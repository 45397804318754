
import './FinalDialog.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';
import Confetti from 'react-confetti'


class FinalDialog extends Component {
  constructor(props) {
    super(props);
    const self = this;

    this.close = this.close.bind(this);
    this.result = this.result.bind(this);
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  result() {

    if (this.props.onResult) {
      this.props.onResult(this.state.numbers);
    }
  }



  render() {

    /*let title = "Buch";
    if(getTranslationService().translate(this.props.selectedObject)){
      title = getTranslationService().translate(this.props.selectedObject);
    }*/

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={this.close}>
          {<Confetti
            width={window.innerWidth}
            height={window.innerHeight}
    />}
        </div>
        <div className="card dialog" onClick={(e) => { e.stopPropagation(); }}>
          <div className="card-content">
            {/*<div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img src="/images/nils.png" alt="Nils Wenzler" />
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-4">Nils Wenzler</p>
                <p className="subtitle is-6">@nilswenzler</p>
              </div>
          </div>*/}
                  
            <div className="content" dangerouslySetInnerHTML={{__html: getTranslationService().translate("final_message")}}>
              

            </div>
          </div>
          {/*<footer className="card-footer">
            <a href="https://paypal.me/nilswenzler/5" target={"_blank"} className="card-footer-item">5 €</a>
            <a href="https://paypal.me/nilswenzler/10" target={"_blank"} className="card-footer-item has-text-white has-background-primary">10 €</a>
            <a href="https://paypal.me/nilswenzler/20" target={"_blank"} className="card-footer-item">20 €</a>
          </footer>*/}
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={this.close}></button>
      </div>
    );
  }

}

export default FinalDialog;
