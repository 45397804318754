
let _instance = undefined;

export function getTranslationService() {
    if (!_instance) {
        _instance = new TranslationService();
    }
    return _instance;
}

class TranslationService {

    constructor() {
        _instance = this;

        this.callbacks = {
            "translationChange": [],
        };

        

        this.updateLang = this.updateLang.bind(this);
        this.setLang = this.setLang.bind(this);

        this.updateLang();
    }

    setLang(language){
        const url_params = new URLSearchParams(window.location.search);
        let lang = url_params.get("lang");
        url_params.set("lang", language);
        window.history.replaceState({}, '', window.location.pathname+"?"+url_params.toString());
        this.updateLang();
    }

    updateLang(){
        
        const self = this;
        const url_params = new URLSearchParams(window.location.search);
        let lang = this.getLang();

        let file = "/static/en.json";
        if(lang === "de"){
            file = "/static/de.json";
        }

		fetch(file)
		.then(response => response.json())
		.then(data => {
            self.translation = data
            self.triggerCallback("translationChange", lang);
        });
    }

    getLang(){
        const url_params = new URLSearchParams(window.location.search);
        let lang = url_params.get("lang");
        if(!lang){
            if(navigator.language.indexOf("de")>=0){
                url_params.set("lang", "de");
                lang = "de";
            }else{
                url_params.set("lang", "en");
            }
            window.history.replaceState({}, '', window.location.pathname+"?"+url_params.toString());
        }
        return lang;
    }

    addCallback(type, func){
        if(this.callbacks[type]){
            this.callbacks[type].push(func);
        }
    }

    triggerCallback(type, data){
        if(this.callbacks[type]){
            for(let cb of this.callbacks[type]){
                cb(data, this);
            }
        }
    }

    translate(input){
        if(!this.translation){
            return undefined;
        }
        return this.translation[input];
    }

}

export default TranslationService;