import './PlayerRepresentation.css';
import 'bulma/css/bulma.min.css';
import { Component, createRef } from 'react';
import { getDataService } from './DataService';
import { getRenderService } from './ThreeRenderService';

import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';


class PlayerRepresentation extends Component {
  constructor(props) {
    super(props);

    this.playernamesUI = createRef();
    this.state = { notifications: [] };
    this.playerPrefab = undefined;
    this.playerMeshs = {};
    this.playerAnimations = {};
    this.playerSounds = {};
    this.animations = {};
    this.outfits = {};

    this.updateStateQueue = [];

    this.loadOutfit = this.loadOutfit.bind(this);

  }

  loadOutfit(outfit, index) {
    const loader = new THREE.TextureLoader();
    const self = this;
    // load a resource
    loader.load(
      // resource URL
      outfit,

      // onLoad callback
      function (texture) {
        // in this example we create the material when the texture is loaded
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.NearestFilter;
        const material = new THREE.MeshPhongMaterial({
          map: texture,
          color: 0x999999,
          shininess: 0
        });
        self.outfits[index] = material;
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function (err) {
        console.error('An error happened.');
      }
    );
  }

  componentDidMount() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    const self = this;

    const loader2 = new FBXLoader();
    loader2.load('/stickfigure_3.fbx', (fbx) => {
      //fbx = fbx.children[0];
      fbx.scale.setScalar(0.011);
      fbx.traverse(child => {
        if (child.isMesh) child.geometry.computeVertexNormals()
      });
      var group = new THREE.Group();
      group.position.y = +0.4;
      group.add(fbx);

      self.loadOutfit('/stickfigure_2_uv_char1.png', 2);
      self.loadOutfit('/stickfigure_2_uv_char2.png', 1);
      self.loadOutfit('/stickfigure_2_uv_char3.png', 3);
      self.loadOutfit('/stickfigure_2_uv_char4.png', 4);

      self.playerPrefab = fbx;

      const animIdle = new FBXLoader();
      animIdle.load('/Anim_Idle.fbx', (anim) => {
        self.animations["idle"] = anim.animations[0];
      });
      const animMove = new FBXLoader();
      animMove.load('/Anim_Walking.fbx', (anim) => {
        self.animations["walking"] = anim.animations[0];
      });
      const animBackwards = new FBXLoader();
      animBackwards.load('/Anim_Walking_Backwards.fbx', (anim) => {
        self.animations["backward"] = anim.animations[0];
      });
      const animLeft = new FBXLoader();
      animLeft.load('/Anim_Walking_Left.fbx', (anim) => {
        self.animations["left"] = anim.animations[0];
      });
      const animRight = new FBXLoader();
      animRight.load('/Anim_Walking_Right.fbx', (anim) => {
        this.animations["right"] = anim.animations[0];
      });

      /*getRenderService().addCallback("scenetick", (timedif, rs) => {
        this._mixer.update(timedif);
      });*/
    });
    // create the PositionalAudio object (passing in the listener)
    //const sound = new THREE.PositionalAudio( listener );
    /*sound.setBuffer( buffer );
      sound.setRefDistance( 20 );
      sound.play();*/

    // load a sound and set it as the PositionalAudio object's buffer
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load( '/sounds/footsteps.mp3', function( buffer ) {
      self.footstepsBuffer = buffer;
    });

    window.setInterval(() => {
      let me = getDataService().getMe();
      if (this.updateStateQueue.length > 0) {
        let aggrState = {};
        for (let update of this.updateStateQueue) {
          Object.assign(aggrState, update);
        }
        me.updateState(aggrState);
        this.updateStateQueue = [];
      }
    }, 100);

    getRenderService().addCallback("scenetick", (timedif, rs) => {

      for (let pak of Object.keys(self.playerAnimations)) {
        self.playerAnimations[pak].mixer.update(timedif)
      }

      if (self.props.autoupdate) {
        let me = getDataService().getMe();
        if (me) {
          let state = me.getState();
          let statePos = state.pos;
          if (!statePos) {
            statePos = { x: rs.playerCollider.end.x, y: rs.playerCollider.end.y, z: rs.playerCollider.end.z };
            me.updateState({ pos: statePos });
          }
          if (me.getState().movement != me.getLocalState().movement) {
            me.updateState({ movement: me.getLocalState().movement });
            me._data.state.movement = me.getLocalState().movement;
            if(this.footstepsBuffer && !this.meFootstepsSound){
              const sound = new THREE.Audio( getRenderService().listener );
              sound.setBuffer( this.footstepsBuffer );
              sound.setLoop(true);
              this.meFootstepsSound = sound;
            }
            if(this.meFootstepsSound && this.meFootstepsSound.isPlaying && me._data.state.movement == "idle"){
              this.meFootstepsSound.stop();
            }
            if(this.meFootstepsSound && !this.meFootstepsSound.isPlaying && me._data.state.movement !== "idle"){
              this.meFootstepsSound.play();
            }
          }
          if (Math.sqrt(Math.pow(statePos.x - rs.playerCollider.end.x, 2) + Math.pow(statePos.y - rs.playerCollider.end.y, 2) + Math.pow(statePos.z - rs.playerCollider.end.z, 2)) > 0.2) {
            statePos = { x: rs.playerCollider.end.x, y: rs.playerCollider.end.y, z: rs.playerCollider.end.z };

            this.updateStateQueue.push({ pos: statePos })
            //me.updateState({ pos: statePos });
          }
          let stateRot = state.rot;
          const quat = new THREE.Quaternion();
          rs.camera.getWorldQuaternion(quat);
          const euler = new THREE.Euler();
          euler.setFromQuaternion(quat, "YXZ");
          if (!stateRot) {
            stateRot = { x: euler.x, y: euler.y, z: euler.z };
            me.updateState({ rot: stateRot });
          }

          if (Math.abs(stateRot.y - euler.y) > 10 * Math.PI / 180.0 || Math.abs(stateRot.x - euler.x) > 10 * Math.PI / 180.0) {
            stateRot = { x: euler.x, y: euler.y, z: euler.z };
            this.updateStateQueue.push({ rot: stateRot });
            //me.updateState({ rot: stateRot });
          }
        }
      }

      // user names ui
      const users = getDataService().getUsers();

      let playerNamesUI = "";

      for (const uId in users) {
        const u = users[uId];
        if (u._data.isMe && !this.props.drawMe) {
          continue;
        }

        if (!u._data.state.pos || !u._data.state.rot) {
          continue;
        }
        if (!this.playerPrefab) {
          continue;
        }
        if (!this.animations["idle"] || !this.animations["walking"] || !this.animations["backward"] || !this.animations["left"] || !this.animations["right"]) {
          continue;
        }
        if(!this.footstepsBuffer){
          continue;
        }

        if (!u._localState.pos || !u._localState.rot) {
          u._localState = JSON.parse(JSON.stringify(u._data.state));
        }

        if (!this.playerMeshs[uId]) {
          let group = new THREE.Group();
          const newModel = SkeletonUtils.clone(this.playerPrefab);
          newModel.no_collision = true;
          for (let c of newModel.children) {
            c.no_collision = true;
          }

          const mixer = new THREE.AnimationMixer(newModel);
          const idleAction = mixer.clipAction(this.animations["idle"]);
          const walkAction = mixer.clipAction(this.animations["walking"]);
          const leftAction = mixer.clipAction(this.animations["left"]);
          const rightAction = mixer.clipAction(this.animations["right"]);
          const backwardAction = mixer.clipAction(this.animations["backward"]);
          idleAction.play();

          this.playerAnimations[uId] = { idle: idleAction, walking: walkAction, left: leftAction, right: rightAction, backward: backwardAction, curAction: "idle", mixer: mixer };

          const sound = new THREE.PositionalAudio( getRenderService().listener );
          sound.setBuffer( this.footstepsBuffer );
          sound.setRefDistance( 20 );
          sound.setLoop(true);
          this.playerSounds[uId] = sound;
          newModel.add(sound);

          this.playerMeshs[uId] = group;
          group.add(newModel);
          rs.scene.add(group);
        } else {
          if (this.playerAnimations[uId].curAction != "idle" && u._data.state.movement == "idle") {
            if (this.playerAnimations[uId][this.playerAnimations[uId].curAction]) {
              this.playerAnimations[uId][this.playerAnimations[uId].curAction].fadeOut(1);
            }
            this.playerAnimations[uId].idle.setEffectiveWeight(1).reset().setEffectiveTimeScale(1).fadeIn(1).play();
            this.playerAnimations[uId].curAction = "idle";
          }
          if (this.playerAnimations[uId].curAction != "walking" && u._data.state.movement == "forward") {
            if (this.playerAnimations[uId][this.playerAnimations[uId].curAction]) {
              this.playerAnimations[uId][this.playerAnimations[uId].curAction].fadeOut(1);
            }
            this.playerAnimations[uId].walking.setEffectiveWeight(1).reset().setEffectiveTimeScale(1).fadeIn(1).play();
            this.playerAnimations[uId].curAction = "walking";
          }
          if (this.playerAnimations[uId].curAction != "left" && u._data.state.movement == "left") {
            if (this.playerAnimations[uId][this.playerAnimations[uId].curAction]) {
              this.playerAnimations[uId][this.playerAnimations[uId].curAction].fadeOut(1);
            }
            this.playerAnimations[uId].left.setEffectiveWeight(1).reset().setEffectiveTimeScale(1).fadeIn(1).play();
            this.playerAnimations[uId].curAction = "left";
          }
          if (this.playerAnimations[uId].curAction != "right" && u._data.state.movement == "right") {
            if (this.playerAnimations[uId][this.playerAnimations[uId].curAction]) {
              this.playerAnimations[uId][this.playerAnimations[uId].curAction].fadeOut(1);
            }
            this.playerAnimations[uId].right.setEffectiveWeight(1).reset().setEffectiveTimeScale(1).fadeIn(1).play();
            this.playerAnimations[uId].curAction = "right";
          }
          if (this.playerAnimations[uId].curAction != "backward" && u._data.state.movement == "backward") {
            if (this.playerAnimations[uId][this.playerAnimations[uId].curAction]) {
              this.playerAnimations[uId][this.playerAnimations[uId].curAction].fadeOut(1);
            }
            this.playerAnimations[uId].backward.setEffectiveWeight(1).reset().setEffectiveTimeScale(1).fadeIn(1).play();
            this.playerAnimations[uId].curAction = "backward";
          }

          if(this.playerAnimations[uId].curAction != "idle" && !this.playerSounds[uId].isPlaying){
            this.playerSounds[uId].play();
          }
          if(this.playerAnimations[uId].curAction == "idle" && this.playerSounds[uId].isPlaying){
            this.playerSounds[uId].stop();
          }

          u._localState.pos.x = (u._data.state.pos.x + u._localState.pos.x * 15) / 16;
          u._localState.pos.y = (u._data.state.pos.y + u._localState.pos.y * 15) / 16;
          u._localState.pos.z = (u._data.state.pos.z + u._localState.pos.z * 15) / 16;

          const degtorad = Math.PI / 180;
          const end = (u._data.state.rot.y + 2 * Math.PI) % (2 * Math.PI);
          const start = (u._localState.rot.y + 2 * Math.PI) % (2 * Math.PI);

          let angle_diff = ((((start - end) % (360 * degtorad)) + (540 * degtorad)) % (360 * degtorad)) - (180 * degtorad);
          u._localState.rot.y -= angle_diff * 0.1;

          u._localState.rot.x = (u._data.state.rot.x + u._localState.rot.x * 15) / 16;

          //u._localState.rot.y = (u._data.state.rot.y + u._localState.rot.y * 15) / 16;
          u._localState.rot.z = (u._data.state.rot.z + u._localState.rot.z * 15) / 16;

          const model = this.playerMeshs[uId];
          model.position.x = u._localState.pos.x;
          model.position.y = u._localState.pos.y - 1.65;
          model.position.z = u._localState.pos.z;

          model.rotation.y = u._localState.rot.y + Math.PI;

          let selectedOutfit = u._data.state.outfit?u._data.state.outfit:1;
          if (this.outfits[selectedOutfit] && model.material != this.outfits[selectedOutfit]) {
            model.traverse(child => {
              if (child.material) child.material = this.outfits[selectedOutfit];
            });
          }
          //model.children[0].rotation.x = u._localState.rot.x;
          let head = model.getObjectByName("mixamorigHead");

          if(head){
            head.rotateX(-u._localState.rot.x*0.8);
          }

          if (u._data.offline) {
            model.visible = false;
          } else {
            model.visible = true;

            const offset = new THREE.Vector3(0, 1.5 + 0.3, 0);
            let screenPos = this.toScreenPosition(model, rs.camera, offset);
            if (screenPos.z < 1 && u._data.state.name) {
              playerNamesUI += "<div class=\"player_name has-text-white\" style=\"left: " + screenPos.x*100 + "%; top: " + screenPos.y*100 + "%;\">" + u._data.state.name + "</div>";
            }
          }


        }
      }

      if (this.playernamesUI.current) {
        this.playernamesUI.current.innerHTML = playerNamesUI;
      }

    });


  }

  toScreenPosition(obj, camera, offset = new THREE.Vector3()) {
    var vector = new THREE.Vector3();

    var widthHalf = 0.5;
    var heightHalf = 0.5;

    obj.updateMatrixWorld();
    vector.setFromMatrixPosition(obj.matrixWorld);
    vector.x += offset.x;
    vector.y += offset.y;
    vector.z += offset.z;
    vector.project(camera);

    vector.x = (vector.x * widthHalf) + widthHalf;
    vector.y = - (vector.y * heightHalf) + heightHalf;

    return {
      x: vector.x,
      y: vector.y,
      z: vector.z
    };

  };

  render() {

    return (
      <div className="player-representation-container" ref={this.playernamesUI}>

      </div>
    );
  }

}

export default PlayerRepresentation;
