import './Notifications.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';


class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = { notifications: [] };
    const self = this;

    this.updateNotifications = this.updateNotifications.bind(this);

    getDataService().addCallback("gameevent", (ev)=>{
			let notifications = this.state.notifications;
      console.log(ev);
      if(ev.data && ev.data.event == "key_lock_failed"){
        if(ev.data.lock){
          notifications.push({msg: getTranslationService().translate("room1_noti_comb_1")+ev.data.combination+getTranslationService().translate("room1_noti_comb_2"), timestamp: (new Date()).getTime()});
        }
      }
      if(ev.data && ev.data.event == "key_pickup"){
        if(ev.data.key.indexOf("Key")>=0){
          notifications.push({msg: getTranslationService().translate("room1_noti_pickup_key"), timestamp: (new Date()).getTime()});
        }else{
          notifications.push({msg: getTranslationService().translate("room1_noti_pickup_puzzle"), timestamp: (new Date()).getTime()});
        }
      }
      if(ev.data && ev.data.event == "sequence_button" && ev.data.sequence == "wall"){
        notifications.push({msg: getTranslationService().translate("room1_noti_seq_brick"), timestamp: (new Date()).getTime()});
      }
      if(ev.data && ev.data.event == "sequence_button" && ev.data.sequence == "button"){
        notifications.push({msg: getTranslationService().translate("room1_noti_seq_plate"), timestamp: (new Date()).getTime()});
      }
      if(ev.data && ev.data.event == "notification"){
        notifications.push({msg: getTranslationService().translate(ev.data.message), timestamp: (new Date()).getTime()});
      }

      self.setState({notifications: notifications});
      window.setTimeout(self.updateNotifications, 4100);
    });

  }

  updateNotifications(){
    let curTime = (new Date()).getTime();
    let notifications = this.state.notifications.filter(not => {
      if(curTime-not.timestamp >= 4000){
        return false;
      }
      return true;
    });
    this.setState({notifications: notifications});
  }
  
  render() {
  
    let notifications = this.state.notifications.map(not => {
      
      return <div className='notification notification-fadeout' key={"not"+not.timestamp}>{not.msg}</div>
    })

    return (
      <div className="notification-container">
        {notifications}
      </div>
    );
  }

}

export default Notifications;
