import './VolumeControl.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';
import LanguageSelector from './LanguageSelector';
import { getRenderService } from './ThreeRenderService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeHigh, faVolumeLow, faVolumeMute } from '@fortawesome/free-solid-svg-icons';


class VolumeControl extends Component {
  constructor(props) {
    super(props);

    this.state = { volume: 80, lastVolume: 80 };
    const self = this;

    this.updateInputValue = this.updateInputValue.bind(this);
    this.updateVolume = this.updateVolume.bind(this);
  }

  updateInputValue(evt) {
    const val = evt.target.value;
    this.updateVolume(val);
  }

  updateVolume(newVal){
    this.setState({
      volume: newVal,
      lastVolume: this.state.volume
    });
    getRenderService().listener.setMasterVolume(newVal/100.0);
  }

  render() {

    return (
          <div>
            <input type="range" min="0" max="100" onChange={this.updateInputValue} value={this.state.volume} style={{marginRight: "0.5rem", cursor:"pointer"}}></input>
            <div onClick={()=>this.updateVolume(0)} style={{"display": (this.state.volume>=51?"inline-block":"none"), "width":"20px", "cursor": "pointer"}}>
                <FontAwesomeIcon icon={faVolumeHigh} size="lg"/>
              </div>
            <div onClick={()=>this.updateVolume(0)} style={{"display": (this.state.volume>0&&this.state.volume<51?"inline-block":"none"), "width":"20px", "cursor": "pointer"}}><FontAwesomeIcon icon={faVolumeLow} size="lg"/></div>
            <div onClick={()=>this.updateVolume(this.state.lastVolume)} style={{"display": (this.state.volume<=0?"inline-block":"none"), "width":"20px", "cursor": "pointer"}}><FontAwesomeIcon icon={faVolumeMute} size="lg"/></div>
          </div>
    );
  }

}

export default VolumeControl;
