import './Room2.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from './framework/ThreeRenderService'
import CombinationLock from './framework/CombinationLock';
import { getDataService } from './framework/DataService';
import BookDialog from './framework/BookDialog';
import Notifications from './framework/Notifications';
import Timer from './framework/Timer';
import * as THREE from 'three';
import TableView from './framework/TableView';
import PlayerRepresentation from './framework/PlayerRepresentation';
import { getTranslationService } from './TranslationService';
import InspectThreeDialog from './framework/InspectThreeDialog';
import FinalDialog from './framework/FinalDialog';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import MenuOverlay from './framework/MenuOverlay';
import { Octree } from './framework/Octree';
import { getSoundService } from './framework/SoundService';

class Room2 extends Component {
  constructor(props) {
    super(props);
    getRenderService().addToDom("three-container", true);
    this.mounted = false;

    this.state = { mounted: false, selectedObject: undefined, playerInitialized: false, pointerLock: false };

    this.updateWorldState = this.updateWorldState.bind(this);
    this.tickScene = this.tickScene.bind(this);
    this.getInteractableParent = this.getInteractableParent.bind(this);
    this.drawDynamicScreen = this.drawDynamicScreen.bind(this);

    this.interactions = {
      "NumberLockDigital1": "combinationlock",
      "NumberLockDigital2": "combinationlock",
      "NumberLockDigital3": "combinationlock",
      "NumberLockDigital4": "combinationlock",
      "SpaceEvacuateTrigger1": "trigger",
      "SpaceEvacuateTrigger2": "trigger",
      "WallBrick_1": "buttonsequence",
      "SpaceBook1": "book",
      "SpaceBook2": "book",
      "SpaceBook3": "book",
      "SpaceBook4": "book",
      "SpaceBook5": "book",
      "SpaceBook6": "book",
      "SpaceBook7": "book",
      "SpaceBook8": "book",
      "Key_1_1": "pickup",
      "space_lab_animal_1": "inspect",
      "space_lab_animal_2": "inspect",
      "space_lab_animal_3": "inspect",
      "Space_Item_Tether_01_238": "inspect",
      "Space_Item_Wrench_01_235": "inspect",
      "Space_Item_Hammer_01_232": "inspect",
      "Space_Item_Extinguisher_01": "inspect",
      "Space_Item_Extinguisher_02": "inspect",
      "Space_Prop_Container_02_188": "inspect",
      "Space_Prop_TestTube_03_179": "inspect",
      "Space_Prop_TestTube_02_176": "inspect",
      "Table_Small": "view",
      "SpaceEnergyTrigger1": "trigger",
      "SpaceEnergyTrigger2": "trigger",
      "SpaceEnergyTrigger3": "trigger",
      "SpaceEnergyTrigger4": "trigger",
      "SpaceEnergyTrigger5": "trigger",
      "SpaceEnergyTrigger6": "trigger"
    };
  }

  drawDynamicScreen() {
    this.screenTextureCtx.canvas.width = 256;
    this.screenTextureCtx.canvas.height = 256;
    let ctx = this.screenTextureCtx;
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, this.screenTextureCtx.canvas.width, this.screenTextureCtx.canvas.height);


    let up = [];
    let down = [];

    if (!this.state.gamestate) {
      return;
    }
    (this.state.gamestate.SpaceEnergyTrigger1 ? up : down).push("#DDBC2C");
    (this.state.gamestate.SpaceEnergyTrigger1 ? up : down).push("#DDBC2C");
    (this.state.gamestate.SpaceEnergyTrigger1 ? up : down).push("#DDBC2C");
    (this.state.gamestate.SpaceEnergyTrigger2 ? up : down).push("#96723A");
    (this.state.gamestate.SpaceEnergyTrigger2 ? up : down).push("#96723A");
    (this.state.gamestate.SpaceEnergyTrigger2 ? up : down).push("#96723A");
    (this.state.gamestate.SpaceEnergyTrigger3 ? up : down).push("#738B51");
    (this.state.gamestate.SpaceEnergyTrigger3 ? up : down).push("#738B51");
    (this.state.gamestate.SpaceEnergyTrigger3 ? up : down).push("#738B51");
    (this.state.gamestate.SpaceEnergyTrigger3 ? up : down).push("#738B51");
    (this.state.gamestate.SpaceEnergyTrigger3 ? up : down).push("#738B51");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger4 ? up : down).push("#2EC284");
    (this.state.gamestate.SpaceEnergyTrigger5 ? up : down).push("#D137BB");
    (this.state.gamestate.SpaceEnergyTrigger5 ? up : down).push("#D137BB");
    (this.state.gamestate.SpaceEnergyTrigger5 ? up : down).push("#D137BB");
    (this.state.gamestate.SpaceEnergyTrigger5 ? up : down).push("#D137BB");
    (this.state.gamestate.SpaceEnergyTrigger5 ? up : down).push("#D137BB");
    (this.state.gamestate.SpaceEnergyTrigger5 ? up : down).push("#D137BB");
    (this.state.gamestate.SpaceEnergyTrigger6 ? up : down).push("#D27C38");
    (this.state.gamestate.SpaceEnergyTrigger6 ? up : down).push("#D27C38");

    const overPowered = up.length > 8;

    if (this.state.overPowered != overPowered) {
      this.setState({ overPowered: overPowered });
      getDataService().updateGameState({ random: Math.random() });
    }

    ctx.strokeStyle = overPowered ? '#f00' : "#fff";
    ctx.lineWidth = 5;
    ctx.beginPath();
    ctx.rect(128 - 30, 128 - 108, 60, 216);
    ctx.stroke();

    console.log(up);
    for (let i = 0; i < up.length; i++) {
      ctx.fillStyle = overPowered ? '#f00' : up[i];
      ctx.fillRect(128 - 20, 128 - 100 + 5 + i * 25, 40, 20);
    }

    this.screenTexture.needsUpdate = true;
  }

  componentDidMount() {
    if (this.mounted) {
      return;
    }
    this.mounted = true;

    this.screenTextureCtx = document.createElement('canvas').getContext('2d');
    //document.body.appendChild(this.screenTextureCtx.canvas);
    this.screenTexture = new THREE.CanvasTexture(this.screenTextureCtx.canvas);

    this.drawDynamicScreen();

    this.screenMaterial = new THREE.MeshBasicMaterial({
      map: this.screenTexture,
    });



    const self = this;
    console.log("Component did mount.");
    if (!this.state.mounted) {
      this.setState({ mounted: true });

      const loader = new GLTFLoader().setPath('/');

      loader.load('space_scene.glb', (gltf) => {

        getRenderService().scene.add(gltf.scene);

        gltf.scene.traverse(child => {
          if (child.type == "PointLight") {
            child.intensity /= 400;
          }
          if (child.name === "Chest_Open") {
            child.visible = false;
          }
          
          if (child.name === "SM_Room1") {
            if (child.material) {
              //debugger;
              //child.material.color = new THREE.Color(0xbbbbbb);
              //child.material.lightmapintensity = 0;
            }
          }
          if (child.name.indexOf("Button_Lamp") >= 0) {
            child.visible = false;
          }
          if (child.name.indexOf("SpaceHolo") >= 0) {
            child.no_collision = true;
            child.visible = false;
          }
          if(child.name.indexOf("Door")==0){
            console.log(child.name);
            let oct = new Octree();
            oct.fromGraphNode(child);
            getRenderService().addOctree(child.name, oct);
            child.no_collision = true;
          }
          if (child.isMesh) {
            if (child.material) {
              child.material.roughness = 1;
              child.material.metalness = 0;
            }
          }

          if(self.interactions[child.name]){
            if(child.name.indexOf("Trigger")>=0){
              getSoundService().loadSound(child.name, '/sounds/switch.mp3', {mesh: child});
            }
            if(child.name.indexOf("Book")>=0){
              getSoundService().loadSound(child.name, '/sounds/book.mp3', {mesh: child});
            }
          }

          if(child.name == "SpaceHoloShip"){
            getSoundService().loadSound(child.name, '/sounds/teleporter_modified.mp3', { mesh: child, loop: true });
          }

          if(child.name.indexOf("Door")>=0){
            getSoundService().loadSound(child.name, '/sounds/mechanical_door.mp3', {mesh: child});
          }

        });

        getRenderService().worldOctree.fromGraphNode(gltf.scene);
        getRenderService().animate();

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);

      }, ( xhr ) => {
        console.log( ( xhr.loaded/46701256*100 ) + '% loaded' );
    
      });

      // create a global audio source
      const sound = new THREE.Audio( getRenderService().listener );

      // load a sound and set it as the Audio object's buffer
      const audioLoader = new THREE.AudioLoader();
      audioLoader.load( '/sounds/spaceship-ambience.mp3', function( buffer ) {
        sound.setBuffer( buffer );
        sound.setLoop( true );
        sound.setVolume( 0.5 );
        sound.play();
      });


      getRenderService().addCallback("hover", (intersects, rs) => {
        if (intersects.length > 0) {
          let intersect = intersects[0];
          for (let i = 0; i < intersects.length; i++) {
            if (intersects[i].object.visible) {
              intersect = intersects[i];
              break;
            }
          }
          const name = intersect.object.name;
          if (intersect.distance > 3 || name.indexOf("SM_") >= 0 || name.indexOf("SpaceHoloLane") >= 0 || !intersect.object.visible) {
            if (!this.state.cameraFocus) {
              rs.outlinePass.selectedObjects = [];
            }
            self.setState({ selectedObject: undefined });
            return;
          }
          if (!this.state.cameraFocus) {
            rs.outlinePass.selectedObjects = [intersects[0].object];
          }
          if (!this.state.selectedObject || intersect.object.name != this.state.selectedObject.object.name) {
            self.setState({ selectedObject: intersect });
          }
        }
      });

      getRenderService().addCallback("mousedown", (intersects, rs) => {
        if (!this.state.selectedObject || this.state.cameraFocus) {
          return;
        }
        let obj = this.getInteractableParent(this.state.selectedObject.object);
        const name = obj.name;
        let currentInteraction = self.interactions[name];

        getSoundService().playSound(name);

        if (currentInteraction) {
          if (currentInteraction == "combinationlock") {
            if (!this.state.overPowered) {
              let show = true;
              if (name == "NumberLockDigital1" && !this.state.gamestate["SpaceEnergyTrigger1"]) {
                show = false;
              }
              if (name == "NumberLockDigital2" && !this.state.gamestate["SpaceEnergyTrigger2"]) {
                show = false;
              }
              if (name == "NumberLockDigital3" && !this.state.gamestate["SpaceEnergyTrigger3"]) {
                show = false;
              }
              if (name == "NumberLockDigital4" && !this.state.gamestate["SpaceEnergyTrigger4"]) {
                show = false;
              }
              if (name == "NumberLockDigital5" && !this.state.gamestate["SpaceEnergyTrigger5"]) {
                show = false;
              }
              if (name == "NumberLockDigital6" && !this.state.gamestate["SpaceEnergyTrigger6"]) {
                show = false;
              }
              if (show) {
                this.setState({ combinationlock: true });
                document.exitPointerLock();
              }
            }
          }
          if (currentInteraction == "book") {
            this.setState({ book: true });
            document.exitPointerLock();
          }
          if (currentInteraction == "inspect") {
            this.setState({ inspect: name });
            document.exitPointerLock();
            getRenderService().enableKeyboardControls = false;
          }
          if (currentInteraction == "buttonsequence" && name.indexOf("Wall") >= 0) {
            getDataService().triggerGameEvent({ "event": "sequence_button", "sequence": "wall", "key": name });
          }
          if (currentInteraction == "buttonsequence" && name.indexOf("Button") >= 0) {
            getDataService().triggerGameEvent({ "event": "sequence_button", "sequence": "button", "key": name.substring(0, name.length - 2) });
          }
          if (currentInteraction == "trigger" && name.indexOf("SpaceEnergyTrigger") >= 0) {
            let new_state = {};
            new_state[name] = (this.state.gamestate[name] == 0) ? 1 : 0;
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("SpaceEvacuateTrigger1") >= 0) {
            if (!this.state.overPowered && this.state.gamestate["SpaceEnergyTrigger5"]) {
              if (this.state.gamestate["targetPlanet"] == 3) {
                if (!this.state.gamestate["successTime"]) {
                  getDataService().updateGameState({ successTime: (new Date()).getTime(), lock5: 1 });
                }
              } else {
                getDataService().triggerGameEvent({ "event": "notification", "message": "SpaceNoPlanetForEvacuation" });
              }
            }
          }
          if (currentInteraction == "trigger" && name.indexOf("SpaceEvacuateTrigger2") >= 0) {
            if (!this.state.overPowered) {
              if (this.state.gamestate["SpaceEnergyTrigger6"]) {
                getDataService().triggerGameEvent({ "event": "notification", "message": "SpaceBetterNot" });
              }
            }
          }
          if (currentInteraction == "pickup") {
            getDataService().triggerGameEvent({ "event": "key_pickup", "key": name });
            let tableSpawner = rs.scene.getObjectByName("Table_Spawner");
            this.state.selectedObject.position.copy(tableSpawner.position);
            this.state.selectedObject.position.x += Math.random() - 0.5;
            this.state.selectedObject.rotation.set(0, 0, 0);

            if (name.indexOf("Puzzle") >= 0) {
              this.state.selectedObject.position.x += Math.random() - 0.5;
            }
            this.state.selectedObject.name = name + "_Movable";
            //triggerEvent(name);
          }
        }
        if (name == "Table_Small") {
          let tableCamera = rs.scene.getObjectByName("Camera").children[0];
          rs.freeCamera = true;
          let tarPos = new THREE.Vector3();
          tableCamera.getWorldPosition(tarPos);

          let tarQuat = new THREE.Quaternion(-0.0111, 0.8049, 0.5930, 0.0151);
          tableCamera.getWorldQuaternion(tarQuat);

          document.exitPointerLock();
          rs.enablePointerLock = false;
          this.setState({ cameraFocus: { position: tarPos, quaternion: tarQuat } });
        }
      });


      getRenderService().addCallback("scenetick", (timediff, rs) => {
        const users = getDataService().getUsers();

        if (this.state.pointerLock != (document.pointerLockElement === document.body)) {
          this.setState({ pointerLock: (document.pointerLockElement === document.body) });
        }

        if (self.state.cameraFocus) {
          var camPos = rs.camera.position.clone();       // Holds current camera position
          var targetPos = this.state.cameraFocus.position.clone();// Target position

          // Interpolate camPos toward targetPos
          rs.camera.position.lerp(targetPos, 3 * timediff);
          rs.camera.quaternion.slerp(this.state.cameraFocus.quaternion, 3 * timediff);
        }

        let playerNamesUI = "";

        for (const uId in users) {
          const u = users[uId];
          if (u._data.isMe) {
            if (!self.state.playerInitialized) {
              if (u._data.state.playerNumber !== undefined) {
                //const spawner = rs.scene.getObjectByName("PlayerSpawner" + ((u._data.state.playerNumber % 2) + 1));
                let spawnPos = new THREE.Vector3(0, 0, 0);
                rs.playerCollider.start.set(0, 0.35, 0);
                rs.playerCollider.end.set(0, 1.7, 0);
                rs.playerCollider.radius = 0.35;
                rs.playerCollider.translate(spawnPos);
                rs.camera.position.copy(rs.playerCollider.end);
                rs.camera.rotation.set(0, 0, 0);
                self.setState({ playerInitialized: true });
              }
            }
            continue;
          }
        }
      });

      getRenderService().addCallback("sceneupdate", (scene, rs) => {
        self.updateWorldState(getDataService().getGameState(), getDataService());
      });
      getDataService().addCallback("gamestate", self.updateWorldState);
    }
  }

  updateWorldState(gameState, ds) {
    this.setState({ gamestate: gameState });

    if (gameState["SpaceEnergyTrigger1"] === undefined) {
      getDataService().updateGameState({ SpaceEnergyTrigger1: 0, SpaceEnergyTrigger2: 0, SpaceEnergyTrigger3: 0, SpaceEnergyTrigger4: 0, SpaceEnergyTrigger5: 0, SpaceEnergyTrigger6: 1 });
    }

    let recalculate = false;

    if (this.state.numberOfHints != gameState.numberOfHints) {
      this.setState({ numberOfHints: gameState.numberOfHints });
    }

    this.drawDynamicScreen();
    const self = this;
    let dialogClose = () => {
      document.body.requestPointerLock();
      getRenderService().enableKeyboardControls = true;
      self.setState({ combinationlock: undefined, book: undefined, inspect: undefined, finalDialog: undefined });
    };

    if (this.state.combinationlock) {

      let selection = undefined;
      if (this.state.selectedObject) {
        let obj = this.getInteractableParent(this.state.selectedObject.object);
        selection = obj.name;
        console.log(selection);
      }
      if (this.state.overPowered) {
        dialogClose();
      }
      if (selection == "NumberLockDigital1" && !gameState.SpaceEnergyTrigger1) {
        dialogClose();
      }
      if (selection == "NumberLockDigital2" && !gameState.SpaceEnergyTrigger2) {
        dialogClose();
      }
      if (selection == "NumberLockDigital3" && !gameState.SpaceEnergyTrigger3) {
        dialogClose();
      }
      if (selection == "NumberLockDigital4" && !gameState.SpaceEnergyTrigger4) {
        dialogClose();
      }
      if (selection == "NumberLockDigital5" && !gameState.SpaceEnergyTrigger5) {
        dialogClose();
      }
      if (selection == "NumberLockDigital6" && !gameState.SpaceEnergyTrigger6) {
        dialogClose();
      }
    }

    getRenderService().scene.traverse((element) => {
      if (element.name && element.name == "Door1") {
        const tarRot = 90 / 180 * Math.PI;
        if (gameState.lock1 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          getSoundService().playSound(element.name);
          let oct = getRenderService().getOctree(element.name);
          if(oct){
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        }
      }

      if (element.name && element.name == "SpaceHoloShip") {
        if (gameState.targetPlanet) {
          let planet = getRenderService().scene.getObjectByName("SpaceHoloPlanet" + gameState.targetPlanet);
          let tarPos = new THREE.Vector3();
          planet.getWorldPosition(tarPos);
          element.position.x = tarPos.x + 0.15;
          element.position.z = tarPos.z + 0.15;
        }
      }

      if (element.name && element.name == "Door4") {
        const tarRot = 90 / 180 * Math.PI;
        if (gameState.lock4 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          getSoundService().playSound(element.name);
          let oct = getRenderService().getOctree(element.name);
          if(oct){
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        }
      }

      if (element.name && element.name.indexOf("SpaceHolo") >= 0) {
        if (!gameState.SpaceEnergyTrigger3 || this.state.overPowered) {
          element.visible = false;
          getSoundService().stopSound("SpaceHoloShip");
        } else {
          element.visible = true;
          getSoundService().playSound("SpaceHoloShip", {loop:true});
        }
      }

      if (element.name && element.name == "NumberLockDigital1") {
        if (!gameState.SpaceEnergyTrigger1 || this.state.overPowered) {
          element.rotation.y = Math.PI;
        } else {
          element.rotation.y = 0;
        }
      }

      if (element.name && element.name == "NumberLockDigital2") {
        if (!gameState.SpaceEnergyTrigger2 || this.state.overPowered) {
          element.rotation.y = Math.PI;
        } else {
          element.rotation.y = 0;
        }
      }
      if (element.name && element.name == "NumberLockDigital3") {
        element.scale.z = !gameState.SpaceEnergyTrigger3 ? -Math.abs(element.scale.y) : Math.abs(element.scale.y);
        element.scale.x = !gameState.SpaceEnergyTrigger3 ? -Math.abs(element.scale.x) : Math.abs(element.scale.x);
      }
      if (element.name && element.name == "NumberLockDigital4") {
        if (!gameState.SpaceEnergyTrigger4 || this.state.overPowered) {
          element.rotation.y = Math.PI;
        } else {
          element.rotation.y = 0;
        }
      }
      if (element.name && element.name == "NumberLockDigital5") {
        if (!gameState.SpaceEnergyTrigger5 || this.state.overPowered) {
          element.rotation.y = Math.PI * 1.5;
        } else {
          element.rotation.y = Math.PI * 0.5;
        }
      }
      if (element.name && element.name == "NumberLockDigital6") {
        if (!gameState.SpaceEnergyTrigger6 || this.state.overPowered) {
          element.rotation.y = Math.PI * 1.5;
        } else {
          element.rotation.y = Math.PI * 0.5;
        }
      }

      if (element.name && element.name == "SpaceCable1") {
        if (gameState.SpaceEnergyTrigger1 && this.state.overPowered) {
          element.material.color = new THREE.Color("red");
        } else {
          element.material.color = !gameState.SpaceEnergyTrigger1 ? new THREE.Color("black") : new THREE.Color(0.7230553030967712, 0.5028864741325378, 0.025186864659190178);
        }
      }
      if (element.name && element.name == "SpaceCable2") {
        if (gameState.SpaceEnergyTrigger2 && this.state.overPowered) {
          element.material.color = new THREE.Color("red");
        } else {
          element.material.color = !gameState.SpaceEnergyTrigger2 ? new THREE.Color("black") : new THREE.Color(0.3049874007701874, 0.16826944053173065, 0.04231142997741699);
        }
      }
      if (element.name && element.name == "SpaceCable3") {
        if (gameState.SpaceEnergyTrigger3 && this.state.overPowered) {
          element.material.color = new THREE.Color("red");
        } else {
          element.material.color = !gameState.SpaceEnergyTrigger3 ? new THREE.Color("black") : new THREE.Color(0.17144113779067993, 0.25818291306495667, 0.08228270709514618);
        }
      }
      if (element.name && element.name == "SpaceCable4") {
        if (gameState.SpaceEnergyTrigger4 && this.state.overPowered) {
          element.material.color = new THREE.Color("red");
        } else {
          element.material.color = !gameState.SpaceEnergyTrigger4 ? new THREE.Color("black") : new THREE.Color(0.027320899069309235, 0.539479672908783, 0.23074008524417877);
        }
      }
      if (element.name && element.name == "SpaceCable5") {
        if (gameState.SpaceEnergyTrigger5 && this.state.overPowered) {
          element.material.color = new THREE.Color("red");
        } else {
          element.material.color = !gameState.SpaceEnergyTrigger5 ? new THREE.Color("black") : new THREE.Color(0.81960784, 0.215686, 0.72762645);
        }
      }
      if (element.name && element.name == "SpaceCable6") {
        if (gameState.SpaceEnergyTrigger6 && this.state.overPowered) {
          element.material.color = new THREE.Color("red");
        } else {
          element.material.color = !gameState.SpaceEnergyTrigger6 ? new THREE.Color("black") : new THREE.Color(0.6444798707962036, 0.2015562802553177, 0.03954625129699707);
        }
      }
      if (element.name && element.name == "SpaceEnergyTrigger1") {
        const tarRot = 180 / 180 * Math.PI;
        element.rotation.y = !gameState.SpaceEnergyTrigger1 ? tarRot : 0;
      }
      if (element.name && element.name == "SpaceEnergyTrigger2") {
        const tarRot = 180 / 180 * Math.PI;
        element.rotation.y = !gameState.SpaceEnergyTrigger2 ? tarRot : 0;
      }
      if (element.name && element.name == "SpaceEnergyTrigger3") {
        const tarRot = 180 / 180 * Math.PI;
        element.rotation.y = !gameState.SpaceEnergyTrigger3 ? tarRot : 0;
      }
      if (element.name && element.name == "SpaceEnergyTrigger4") {
        const tarRot = 180 / 180 * Math.PI;
        element.rotation.y = !gameState.SpaceEnergyTrigger4 ? tarRot : 0;
      }
      if (element.name && element.name == "SpaceEnergyTrigger5") {
        element.scale.z = !gameState.SpaceEnergyTrigger5 ? -Math.abs(element.scale.z) : Math.abs(element.scale.z);
      }
      if (element.name && element.name == "SpaceEnergyTrigger6") {
        element.scale.z = !gameState.SpaceEnergyTrigger6 ? -Math.abs(element.scale.z) : Math.abs(element.scale.z);
      }
      if (element.name && element.name == "Door2") {
        const tarRot = 90 / 180 * Math.PI;
        if (gameState.lock2 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          getSoundService().playSound(element.name);
          let oct = getRenderService().getOctree(element.name);
          if(oct){
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        }
      }

      if (element.name && element.name == "Door3") {
        const tarRot = 45 / Math.PI;
        if (gameState.lock3 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          getSoundService().playSound(element.name);
          let oct = getRenderService().getOctree(element.name);
          if(oct){
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        }
      }
      if (element.name && element.name == "Door5") {
        const tarRot = Math.PI;
        if (gameState.lock5 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          getSoundService().playSound(element.name);
          let oct = getRenderService().getOctree(element.name);
          if(oct){
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }

          if (!gameState["successTime"]) {
            getDataService().updateGameState({ successTime: (new Date()).getTime() });
          }
        }
      }

      if (element.name && element.name == "SpaceDynamicScreen") {
        console.log("SpaceDynamicScreen", element);
        element.material = this.screenMaterial;
      }
    });

    if (recalculate) {
      getRenderService().worldOctree.clear();
      getRenderService().worldOctree.fromGraphNode(getRenderService().scene);
    }
  }

  tickScene(tick, rs) {

  }

  getInteractableParent(obj) {
    for (let i = 0; i < 10; i++) {
      if (!this.interactions[obj.name]) {
        if (obj.parent && obj.parent.name != "Scene") {
          obj = obj.parent;
        } else {
          break;
        }
      } else {
        break;
      }
    }
    return obj;
  }

  render() {
    const self = this;
    let selection = undefined;
    if (this.state.selectedObject) {
      let obj = this.getInteractableParent(this.state.selectedObject.object);
      selection = obj.name;
      console.log(selection);
    }

    let gameState = getDataService().getGameState();
    if (gameState.successTime && !this.state.finalDialogShown && !window.finalDialogTimer) {
      window.finalDialogTimer = window.setTimeout(() => {
        document.exitPointerLock();
        self.setState({ finalDialog: true, finalDialogShown: true });
      }, 5000);

    }

    let dialogClose = () => {
      document.body.requestPointerLock();
      getRenderService().enableKeyboardControls = true;
      self.setState({ combinationlock: undefined, book: undefined, inspect: undefined, finalDialog: undefined });
    };

    let combinationLockResult = (numbers) => {
      dialogClose();
      switch (this.state.selectedObject.object.name) {
        case "NumberLockDigital1":
          if (numbers[0] == 3 && numbers[1] == 5 && numbers[2] == 3) {
            getDataService().updateGameState({ lock1: 1 });
            return true;
          }
          break;
        case "NumberLockDigital2":
          if (numbers[0] == 0 && numbers[1] == 4 && numbers[2] == 6) {
            getDataService().updateGameState({ lock2: 1 });
            return true;
          }
          break;
        case "NumberLockDigital3":
          if (numbers[0] == 5 && numbers[1] == 4 && numbers[2] == 5) {
            getDataService().updateGameState({ targetPlanet: 1 });
            return true;
          }
          if (numbers[0] == 4 && numbers[1] == 8 && numbers[2] == 4) {
            getDataService().updateGameState({ targetPlanet: 2 });
            return true;
          }
          if (numbers[0] == 9 && numbers[1] == 8 && numbers[2] == 3) {
            getDataService().updateGameState({ targetPlanet: 3 });
            return true;
          }
          if (numbers[0] == 1 && numbers[1] == 0 && numbers[2] == 1) {
            getDataService().updateGameState({ targetPlanet: 4 });
            return true;
          }
          if (numbers[0] == 8 && numbers[1] == 3 && numbers[2] == 5) {
            getDataService().updateGameState({ targetPlanet: 5 });
            return true;
          }
          if (numbers[0] == 7 && numbers[1] == 6 && numbers[2] == 2) {
            getDataService().updateGameState({ targetPlanet: 6 });
            return true;
          }
          if (numbers[0] == 5 && numbers[1] == 8 && numbers[2] == 2) {
            getDataService().updateGameState({ targetPlanet: 7 });
            return true;
          }
          if (numbers[0] == 6 && numbers[1] == 4 && numbers[2] == 8) {
            getDataService().updateGameState({ targetPlanet: 8 });
            return true;
          }
          break;
        case "NumberLockDigital4":
          if (numbers[0] == 1 && numbers[1] == 0 && numbers[2] == 7) {
            getDataService().updateGameState({ lock4: 1 });
            return true;
          }
          break;
        case "NumberLockDigital5":
          if (numbers[0] == 0 && numbers[1] == 0 && numbers[2] == 0) {
            getDataService().updateGameState({ lock5: 1 });
            return true;
          }
          break;
      }
      return false;
    };

    let combinationlock = "";
    if (this.state.combinationlock) {
      combinationlock = <CombinationLock selectedObject={selection} onResult={combinationLockResult} onClose={dialogClose} />;
    }

    let book = "";
    if (this.state.book) {
      book = <BookDialog selectedObject={selection} onResult={dialogClose} onClose={dialogClose} />;
    }
    let cursor = [];
    if (this.state.pointerLock) {
      let translation = getTranslationService().translate(selection);

      let interaction = "";
      let currentInteraction = self.interactions[selection]
      if (currentInteraction) {
        interaction = [<br />,
        <div className={"tag is-link"}>
          {getTranslationService().translate(currentInteraction)}
        </div>]
      }

      cursor = [
        <div className={"cursor " + (this.state.selectedObject ? " cursor-hover " : "")}></div>];
      //if(getTranslationService().translate(selection)){
      cursor.push(<div key="cursor" style={{ width: "100%" }} className={"selection " + (selection && translation ? "selection-hover" : "")}>
        <div className={"tag is-dark"}>
          {translation}
        </div>
        {interaction}
      </div>);

cursor.push(<div className='bottom-right-hint has-text-white'>
<table >
  <tbody>
  <tr>
    <td className='has-text-right'><span className='keyboard-key'>Esc</span></td>
    <td className='pl-2'>{getTranslationService().translate("Menü")}</td>
  </tr>
  <tr>
    <td className='has-text-right pt-2'><span className='keyboard-key'>W</span><span className='keyboard-key'>A</span><span className='keyboard-key'>S</span><span className='keyboard-key'>D</span></td>
    <td className='pl-2 pt-2'>{getTranslationService().translate("Bewegen")}</td>
  </tr>
  <tr>
    <td className='has-text-right  pt-2' ><span className='keyboard-key' style={{ width: "6rem" }}>{getTranslationService().translate("Leertaste")}</span></td>
    <td className='pl-2 pt-2'>{getTranslationService().translate("Springen")}</td>
  </tr>
  </tbody>
</table>
</div>);
      //}
    } else {
      const gamestate = this.state.gamestate;
      const hints = [];
      if (this.state.gamestate) {
        // open first door
        hints.push({ hint: getTranslationService().translate("room2_hint_1_1"), solved: gamestate.lock1 || gamestate.SpaceEnergyTrigger1 });
        hints.push({ hint: getTranslationService().translate("room2_hint_1_2"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room2_hint_1_3"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room2_hint_1_4"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room2_hint_1_5"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room2_hint_1_6"), solved: gamestate.lock1 });
        // open second door
        hints.push({ hint: getTranslationService().translate("room2_hint_2_1"), solved: gamestate.lock2 });
        hints.push({ hint: getTranslationService().translate("room2_hint_2_2"), solved: gamestate.lock2 });
        hints.push({ hint: getTranslationService().translate("room2_hint_2_3"), solved: gamestate.lock2 });
        hints.push({ hint: getTranslationService().translate("room2_hint_2_4"), solved: gamestate.lock2 });
        // open third door
        hints.push({ hint: getTranslationService().translate("room2_hint_3_1"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room2_hint_3_2"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room2_hint_3_3"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room2_hint_3_4"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room2_hint_3_5"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room2_hint_3_6"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room2_hint_3_7"), solved: gamestate.lock4 });

        // open final door
        hints.push({ hint: getTranslationService().translate("room2_hint_4_1"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room2_hint_4_2"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room2_hint_4_3"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room2_hint_4_4"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room2_hint_4_5"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room2_hint_4_6"), solved: gamestate.lock5 });

      }
      if (!(this.state.combinationlock || this.state.book || this.state.cameraFocus || this.state.inspect)) {
        cursor = <MenuOverlay onClose={dialogClose} gamestate={gamestate} hints={hints} onShowMessage={() => { self.setState({ finalDialog: true }); document.exitPointerLock(); }} />;
      }
    }


    let tableView = "";
    if (this.state.cameraFocus) {
      tableView = <TableView onLeave={() => {
        this.setState({ cameraFocus: undefined });
        getRenderService().freeCamera = false;
        getRenderService().enablePointerLock = true;
        document.body.requestPointerLock();
        getRenderService().camera.rotation.x = 0;
        getRenderService().camera.rotation.z = 0;
      }} />;
    }

    let inspect = "";
    if (this.state.inspect) {
      inspect = <InspectThreeDialog selectedObject={this.state.inspect} onClose={dialogClose} />
    }

    getRenderService().enableKeyboardControls = !(this.state.cameraFocus || this.state.book || this.state.inspect || this.state.combinationlock);
    // 
    return (
      <div className="App">
        {combinationlock}
        {book}
        {tableView}
        {inspect}
        <PlayerRepresentation autoupdate={1} />
        <Timer />
        <Notifications />
        {this.state.finalDialog ? <FinalDialog onClose={dialogClose} /> : cursor}
        
      </div>
    );
  }

}

export default Room2;
