import './BuyPage.css';
import '../App.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from '../framework/ThreeRenderService'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaw, faCube, faLayerGroup, faEarthAmericas, faPeopleGroup, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faChrome } from '@fortawesome/free-brands-svg-icons'

import { getTranslationService } from '../TranslationService';
import LanguageSelector from '../framework/LanguageSelector';
import PlayerRepresentation from '../framework/PlayerRepresentation';
import { getDataService } from '../framework/DataService';
import { Crowded } from '../crowded';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import CheckoutComponent from '../CheckoutComponent';

class BuyPage extends Component {
  constructor(props) {
    super(props);

    this.state = { users: {}, connected: false, loading: false, session_key: "", burger: false };
  }


  componentDidMount() {

  }

  render() {
    const self = this;

    return [
      <section class="hero is-info is-medium is-bold" id="home">
        <div class="hero-head">
          <nav class="navbar">
            <div class="container">
              <div class="navbar-brand">
                <a class="navbar-item" href="../">

                  <img className="image" src="/images/escape_party.svg" alt="" style={{ maxHeight: "unset" }} width="120px" />
                </a>
                <span className={"navbar-burger burger " + (this.state.burger ? "is-active" : "")} data-target="navbarMenu" onClick={() => { self.setState({ burger: !this.state.burger }) }}>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenu" className={"navbar-menu " + (this.state.burger ? "is-active" : "")} style={{ "background": "transparent" }}>
                <div class="navbar-end">
                  <div class="tabs is-right">
                    <ul>
                      <li class="is-active"><a href="#home">Home</a></li>
                      <li><a href="#features">Kaufen</a></li>
                    </ul>
                    <span class="navbar-item">
                      <LanguageSelector inline={true}></LanguageSelector>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div class="hero-body pt-6 pb-6">
          <div class="container has-text-centered">
            <h1 class="title">
              Ihr Escape Room Zugang
            </h1>
            <h2 class="subtitle">
              Mit einem Schlüssel zu unseren Escape Rooms öffnet sich Ihnen die Türe zu einem digitalen Abenteuer
            </h2>
          </div>
        </div>
      </section>,

<div class="box cta mb-0">
<p class="has-text-centered">
  <span class="tag is-primary">Einführungsangebot</span> Sparen Sie für eine beschränkte Zeit 50% beim Kauf unserer Escape Rooms.
</p>
</div>,
      <section className='container section' id="testimonials">
        <h1 class="title has-text-centered mb-6">
          Zugangsschlüssel kaufen
        </h1>
        <div class="columns is-centered  is-vcentered">
          <div class="column is-half">
            <article class="tile is-child card is-white">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img src="/images/rooms/AztecTemplePeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">Raumzugang</p>
                    <p class="subtitle is-6">für eine Escape Room Session</p>
                    <div className='content'>
                      <ul>
                        <li>mindestens 60 Minuten garantierte Spieldauer</li>
                        <li>für 2-6 Spieler</li>
                        <li>Raumauswahl zusammen mit Spielern direkt vor Spielbeginn</li>
                        <li>Nutzung/Einlösung zu beliebigen Zeitpunkt</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div class="column is-one-third">
            <h1 class="title is-1" style={{ "textAlign": "end" }}><span style={{ "fontSize": "1rem", "marginRight": "0.5rem" }}>nur </span>
              <span className='has-text-weight-light' style={{ "textDecoration": "line-through" }}>39,99€</span>
              <br />
              19,99€ <div style={{ "fontSize": "1rem" }}> pro Schlüssel </div></h1>
            <div class="field">
              <label class="label" style={{ "textAlign": "center" }}>Anzahl Schlüssel</label>
              <div class="control">
                <input class="input" type="text" value="1" />
              </div>
            </div>
            <div class="notification is-light">
              E-Mail-Adresse und Rechnungsadresse werden im Bezahlvorgang abgefragt.
            </div>
            <CheckoutComponent></CheckoutComponent>
          </div>
        </div>


      </section>
      ,

      <section class="container section" id="rooms">
        <div class="intro column is-12 has-text-centered">
          <p class="subtitle">
            Mit einem Schlüssel zu Escape Party können Sie einen beliebigen Raum unserer Sammlung spielen.<br />
            Die Auswahl können Sie am Spieltag zusammen mit Ihren Mitstreitern treffen.
          </p>
        </div>
        <div class="sandbox mt-4">
          <div class="tile is-ancestor">
            <div class="tile is-parent is-shady">
              <article class="tile is-child card is-white">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img src="/images/rooms/AztecTemplePeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">{getTranslationService().translate("lobby_room3")}</p>
                      <p class="subtitle is-6">{getTranslationService().translate("lobby_room3_desc")}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div class="tile is-parent is-shady">
              <article class="tile is-child card is-white">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img src="/images/rooms/DungeonPeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">{getTranslationService().translate("lobby_room1")}</p>
                      <p class="subtitle is-6">{getTranslationService().translate("lobby_room1_desc")}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div class="tile is-parent is-shady">
              <article class="tile is-child card is-white">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img src="/images/rooms/SpaceshipPeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">{getTranslationService().translate("lobby_room2")}</p>
                      <p class="subtitle is-6">{getTranslationService().translate("lobby_room2_desc")}</p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>,
      <footer class="footer">
        <div class="container">
          <div class="columns">
            <div class="column is-3 is-offset-2">
              <a className="is-inline-block" href="https://uluni.de">
                <img className="image" src="/images/uluni.svg" alt="" width="96px" /></a>

            </div>
            <div class="column is-3">
              <h2><strong>Nils Wenzler Informatik</strong></h2>
              <ul>
                <li><a href="/impressum.html">Impressum</a></li>
              </ul>
            </div>
            <div class="column is-4">
            </div>
          </div>

        </div>
        <script src="../js/bulma.js"></script>
      </footer>

    ];
  }

}


export default BuyPage;
