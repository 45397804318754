import './Room3.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from './framework/ThreeRenderService'
import CombinationLock from './framework/CombinationLock';
import { getDataService } from './framework/DataService';
import BookDialog from './framework/BookDialog';
import Notifications from './framework/Notifications';
import Timer from './framework/Timer';
import * as THREE from 'three';
import TableView from './framework/TableView';
import PlayerRepresentation from './framework/PlayerRepresentation';
import { getTranslationService } from './TranslationService';
import InspectThreeDialog from './framework/InspectThreeDialog';
import FinalDialog from './framework/FinalDialog';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import MenuOverlay from './framework/MenuOverlay';
import { Octree } from './framework/Octree';
import { getSoundService } from './framework/SoundService';

class Room3 extends Component {
  constructor(props) {
    super(props);
    getRenderService().addToDom("three-container", true);
    this.mounted = false;

    this.state = { mounted: false, selectedObject: undefined, playerInitialized: false, pointerLock: false };

    this.updateWorldState = this.updateWorldState.bind(this);
    this.tickScene = this.tickScene.bind(this);
    this.getInteractableParent = this.getInteractableParent.bind(this);

    this.interactions = {
      "Temple_Room_0_Button_A": "trigger",
      "Temple_Room_0_Button_B": "trigger",
      "Temple_Room_0_Button_C": "trigger",
      "Temple_Maze_Button_A_1": "trigger",
      "Temple_Maze_Button_B_2": "trigger",
      "Temple_Maze_Button_B_1": "trigger",
      "Temple_Maze_Button_C": "trigger",
      "Temple_Counter_1_Down": "trigger",
      "Temple_Counter_1_Up": "trigger",
      "Temple_Counter_2_Down": "trigger",
      "Temple_Counter_2_Up": "trigger",
      "Temple_Counter_3_Down": "trigger",
      "Temple_Counter_3_Up": "trigger",
      "Temple_Mastermind_1_1": "trigger",
      "Temple_Mastermind_1_2": "trigger",
      "Temple_Mastermind_1_3": "trigger",
      "Temple_Mastermind_1_4": "trigger",
      "Temple_Mastermind_1_5": "trigger",
      "Temple_Mastermind_1_6": "trigger",
      "Temple_Mastermind_2_1": "trigger",
      "Temple_Mastermind_2_2": "trigger",
      "Temple_Mastermind_2_3": "trigger",
      "Temple_Mastermind_2_4": "trigger",
      "Temple_Mastermind_2_5": "trigger",
      "Temple_Mastermind_2_6": "trigger",
      "Temple_Mastermind_3_1": "trigger",
      "Temple_Mastermind_3_2": "trigger",
      "Temple_Mastermind_3_3": "trigger",
      "Temple_Mastermind_3_4": "trigger",
      "Temple_Mastermind_3_5": "trigger",
      "Temple_Mastermind_3_6": "trigger",
      "Temple_Room3_Laser_Button": "trigger",
      "Temple_Mastermind_Reset": "trigger",
      "Temple_Sign_1": "inspect",
      "Temple_Sign_2": "inspect",
      "Temple_Sign_3": "inspect",
      "Temple_Sign_4": "inspect",
      "Temple_Sign_5": "inspect",
      "Temple_Sign_6": "inspect",
      "Temple_Vase_1": "inspect",
      "Temple_Vase_2": "inspect",
      "Temple_Vase_3": "inspect",
      "Temple_MainRoom_Lock": "combinationlock"
    };
  }

  componentDidMount() {
    if (this.mounted) {
      return;
    }
    this.mounted = true;

    const sound = new THREE.Audio(getRenderService().listener);
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load('/sounds/ancient_temple_slow.mp3', function (buffer) {
      sound.setBuffer(buffer);
      sound.setLoop(true);
      sound.setVolume(0.1);
      sound.play();
    });

    const self = this;
    console.log("Component did mount.");
    if (!this.state.mounted) {
      this.setState({ mounted: true });

      const loader = new GLTFLoader().setPath('/');

      loader.load('temple_scene.glb', (gltf) => {

        getRenderService().scene.add(gltf.scene);

        gltf.scene.traverse(child => {
          if (child.type == "PointLight") {
            child.intensity /= 400;
          }
          if (child.name.indexOf("Temple_Laser") >= 0) {
            child.visible = false;
          }
          if (child.name && child.name.indexOf("Temple_Mastermind_Display") >= 0) {
            child.material = child.material.clone();
            child.material.needsUpdate = true;
          }
          if (child.name && child.name.indexOf("Temple_MaterSign") >= 0) {
            child.material = child.material.clone();
            child.material.needsUpdate = true;
          }

          if (child.name.indexOf("Dungeon_Door") >= 0 || child.name == "Exit_Door" || child.name == "Final_Door") {
            let oct = new Octree();
            oct.fromGraphNode(child);
            getRenderService().addOctree(child.name, oct);
            child.no_collision = true;
          }

          if (self.interactions[child.name] == "trigger") {
            getSoundService().loadSound(child.name, '/sounds/rock_trigger.mp3', { mesh: child });
          }

          if (child.name.indexOf("Temple_Room0_Door") >= 0) {
            getSoundService().loadSound(child.name, '/sounds/rock_sliding.mp3', { mesh: child });
          }

          if (child.name.indexOf("Temple_Room0_Door_1") >= 0) {
            self.Room0_Wall_0 = child;
            self.Room0_Wall_0_initial = child.position.clone();
          }
          if (child.name.indexOf("Temple_Room0_Door_2") >= 0) {
            self.Room0_Wall_1 = child;
            self.Room0_Wall_1_initial = child.position.clone();
          }
          if (child.name.indexOf("Temple_Room0_Door_3") >= 0) {
            self.Room0_Wall_2 = child;
            self.Room0_Wall_2_initial = child.position.clone();
          }
          if (child.name.indexOf("Temple_Counter_1_Display") >= 0) {
            self.Temple_Counter_1_Display = child;
            self.Temple_Counter_1_Display_initial = child.position.clone();
          }
          if (child.name.indexOf("Temple_Counter_2_Display") >= 0) {
            self.Temple_Counter_2_Display = child;
            self.Temple_Counter_2_Display_initial = child.position.clone();
          }
          if (child.name.indexOf("Temple_Counter_3_Display") >= 0) {
            self.Temple_Counter_3_Display = child;
            self.Temple_Counter_3_Display_initial = child.position.clone();
          }
          if (child.name.indexOf("Temple_Laser") >= 0) {
            child.no_collision = true;
            getSoundService().loadSound(child.name, '/sounds/teleporter_modified.mp3', { mesh: child, loop: true });
          }

          if (child.name.indexOf("Temple_Room0_Door") >= 0) {
            child.no_collision = true;
          }

          if (child.name.indexOf("Temple_Room0_Wall") >= 0 || child.name.indexOf("Temple_Maze_Wall_") >= 0) { //
            child.visible = false;
            let oct = new Octree();
            oct.fromGraphNode(child);
            getRenderService().addOctree(child.name, oct);
            child.no_collision = true;
          }
          if (child.name == "Temple_MainRoom_Lock" || child.name == "Temple_MainRoom_Door" || child.name == "Temple_MainRoom_Final_Door") {
            child.visible = true;
            let oct = new Octree();
            oct.fromGraphNode(child);
            getRenderService().addOctree(child.name, oct);
            child.no_collision = true;
          }

          if (child.name === "SM_Room1") {
            if (child.material) {
              //debugger;
              //child.material.color = new THREE.Color(0xbbbbbb);
              //child.material.lightmapintensity = 0;
            }
          }
          if (child.name.indexOf("Button_Lamp") >= 0) {
            child.visible = false;
          }
          if (child.name.indexOf("SpaceHolo") >= 0) {
            child.no_collision = true;
            child.visible = false;
          }
          if (child.isMesh) {
            if (child.material) {
              child.material.roughness = 1;
              child.material.metalness = 0;
            }
          }

        });

        getRenderService().worldOctree.fromGraphNode(gltf.scene);
        getRenderService().animate();

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);

      }, (xhr) => {
        console.log((xhr.loaded / 46701256 * 100) + '% loaded');

      });

      getRenderService().addCallback("hover", (intersects, rs) => {
        if (intersects.length > 0) {
          let intersect = intersects[0];
          for (let i = 0; i < intersects.length; i++) {
            if (intersects[i].object.visible) {
              intersect = intersects[i];
              break;
            }
          }
          const name = intersect.object.name;
          if (intersect.distance > 3 || name.indexOf("SM_") >= 0 || name.indexOf("SpaceHoloLane") >= 0 || !intersect.object.visible) {
            if (!this.state.cameraFocus) {
              rs.outlinePass.selectedObjects = [];
            }
            self.setState({ selectedObject: undefined });
            return;
          }
          if (!this.state.cameraFocus) {
            rs.outlinePass.selectedObjects = [intersects[0].object];
          }
          if (!this.state.selectedObject || intersect.object.name != this.state.selectedObject.object.name) {
            self.setState({ selectedObject: intersect });
          }
        }
      });

      getRenderService().addCallback("mousedown", (intersects, rs) => {
        if (!this.state.selectedObject || this.state.cameraFocus) {
          return;
        }

        let obj = this.getInteractableParent(this.state.selectedObject.object);
        const name = obj.name;

        getSoundService().playSound(name);

        let currentInteraction = self.interactions[name];
        if (currentInteraction) {
          if (currentInteraction == "combinationlock") {
            this.setState({ combinationlock: true });
            document.exitPointerLock();
          }
          if (currentInteraction == "book") {
            this.setState({ book: true });
            document.exitPointerLock();
          }
          if (currentInteraction == "inspect") {
            this.setState({ inspect: name });
            document.exitPointerLock();
            getRenderService().enableKeyboardControls = false;
          }

          if (currentInteraction == "trigger" && name.indexOf("Temple_Maze_Button_A") >= 0) {
            let new_state = { Temple_Maze_A: (self.state.gamestate.Temple_Maze_A ? 0 : 1) };
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Maze_Button_B") >= 0) {
            let new_state = { Temple_Maze_B: (self.state.gamestate.Temple_Maze_B ? 0 : 1) };
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Maze_Button_C") >= 0) {
            let new_state = { Temple_Maze_C: (self.state.gamestate.Temple_Maze_C ? 0 : 1) };
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Mastermind_Reset") >= 0) {
            let new_state = { mm1: -1, mm2: -1, mm3: -1 };
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Mastermind_1") >= 0) {
            if (self.state.gamestate && !(self.state.gamestate.mm1 >= 0)) {
              let new_state = { mm1: parseInt(name.substr(-1)) };
              getDataService().updateGameState(new_state);
            }
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Mastermind_2") >= 0) {
            if (self.state.gamestate && !(self.state.gamestate.mm2 >= 0)) {
              let new_state = { mm2: parseInt(name.substr(-1)) };
              getDataService().updateGameState(new_state);
            }
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Mastermind_3") >= 0) {
            if (self.state.gamestate && !(self.state.gamestate.mm3 >= 0)) {
              let new_state = { mm3: parseInt(name.substr(-1)) };
              getDataService().updateGameState(new_state);
            }
          }

          if (currentInteraction == "trigger" && name.indexOf("Temple_Room_0_Button_A") >= 0) {
            let new_state = {};
            let A = (this.state.gamestate["Temple_Room_0_Wall_0"] == 0) ? 0 : 1;
            let B = (this.state.gamestate["Temple_Room_0_Wall_2"] == 0) ? 0 : 1;
            new_state["Temple_Room_0_Wall_0"] = B;
            new_state["Temple_Room_0_Wall_2"] = A;
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Room_0_Button_B") >= 0) {
            let new_state = {};
            let A = (this.state.gamestate["Temple_Room_0_Wall_0"] == 0) ? 0 : 1;
            let B = (this.state.gamestate["Temple_Room_0_Wall_1"] == 0) ? 0 : 1;
            new_state["Temple_Room_0_Wall_0"] = B;
            new_state["Temple_Room_0_Wall_1"] = A;
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Room_0_Button_C") >= 0) {
            let new_state = {};
            new_state["Temple_Room_0_Wall_1"] = (this.state.gamestate["Temple_Room_0_Wall_1"] == 0) ? 1 : 0;
            getDataService().updateGameState(new_state);
          }

          if (currentInteraction == "trigger" && name.indexOf("Temple_Counter_1_Up") >= 0) {
            let new_state = {};
            new_state["Temple_Counter_1"] = Math.min(4, this.state.gamestate["Temple_Counter_1"] + 1);
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Counter_1_Down") >= 0) {
            let new_state = {};
            new_state["Temple_Counter_1"] = Math.max(0, this.state.gamestate["Temple_Counter_1"] - 1);
            getDataService().updateGameState(new_state);
          }

          if (currentInteraction == "trigger" && name.indexOf("Temple_Counter_2_Up") >= 0) {
            let new_state = {};
            new_state["Temple_Counter_2"] = Math.min(4, this.state.gamestate["Temple_Counter_2"] + 1);
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Counter_2_Down") >= 0) {
            let new_state = {};
            new_state["Temple_Counter_2"] = Math.max(0, this.state.gamestate["Temple_Counter_2"] - 1);
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Counter_3_Up") >= 0) {
            let new_state = {};
            new_state["Temple_Counter_3"] = Math.min(4, this.state.gamestate["Temple_Counter_3"] + 1);
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Counter_3_Down") >= 0) {
            let new_state = {};
            new_state["Temple_Counter_3"] = Math.max(0, this.state.gamestate["Temple_Counter_3"] - 1);
            getDataService().updateGameState(new_state);
          }
          if (currentInteraction == "trigger" && name.indexOf("Temple_Room3_Laser_Button") >= 0) {
            let new_state = {};
            new_state["laser3"] = 1;
            getDataService().updateGameState(new_state);
          }


          if (currentInteraction == "pickup") {
            getDataService().triggerGameEvent({ "event": "key_pickup", "key": name });
            let tableSpawner = rs.scene.getObjectByName("Table_Spawner");
            this.state.selectedObject.position.copy(tableSpawner.position);
            this.state.selectedObject.position.x += Math.random() - 0.5;
            this.state.selectedObject.rotation.set(0, 0, 0);

            if (name.indexOf("Puzzle") >= 0) {
              this.state.selectedObject.position.x += Math.random() - 0.5;
            }
            this.state.selectedObject.name = name + "_Movable";
            //triggerEvent(name);
          }
        }
      });


      getRenderService().addCallback("scenetick", (timediff, rs) => {
        const users = getDataService().getUsers();

        if (this.state.pointerLock != (document.pointerLockElement === document.body)) {
          this.setState({ pointerLock: (document.pointerLockElement === document.body) });
        }

        if (self.state.gamestate) {
          let tar0 = !self.state.gamestate.Temple_Room_0_Wall_0 ? self.Room0_Wall_0_initial.x : (self.Room0_Wall_0_initial.x + 1.3);
          let tar1 = !self.state.gamestate.Temple_Room_0_Wall_1 ? self.Room0_Wall_1_initial.x : (self.Room0_Wall_1_initial.x - 1.3);
          let tar2 = !self.state.gamestate.Temple_Room_0_Wall_2 ? self.Room0_Wall_2_initial.y : (self.Room0_Wall_2_initial.y + 2);

          self.Room0_Wall_0.position.x = (self.Room0_Wall_0.position.x * 20 + tar0) / 21;
          self.Room0_Wall_1.position.x = (self.Room0_Wall_1.position.x * 20 + tar1) / 21;
          self.Room0_Wall_2.position.y = (self.Room0_Wall_2.position.y * 20 + tar2) / 21;


          let tarC1 = self.Temple_Counter_1_Display_initial.y + 0.05 * self.state.gamestate.Temple_Counter_1;
          self.Temple_Counter_1_Display.position.y = (self.Temple_Counter_1_Display.position.y * 10 + tarC1) / 11;
          let tarC2 = self.Temple_Counter_2_Display_initial.y + 0.05 * self.state.gamestate.Temple_Counter_2;
          self.Temple_Counter_2_Display.position.y = (self.Temple_Counter_2_Display.position.y * 10 + tarC2) / 11;
          let tarC3 = self.Temple_Counter_3_Display_initial.y + 0.05 * self.state.gamestate.Temple_Counter_3;
          self.Temple_Counter_3_Display.position.y = (self.Temple_Counter_3_Display.position.y * 10 + tarC3) / 11;

        }


        if (self.state.cameraFocus) {
          var camPos = rs.camera.position.clone();       // Holds current camera position
          var targetPos = this.state.cameraFocus.position.clone();// Target position

          // Interpolate camPos toward targetPos
          rs.camera.position.lerp(targetPos, 3 * timediff);
          rs.camera.quaternion.slerp(this.state.cameraFocus.quaternion, 3 * timediff);
        }


        let playerNamesUI = "";

        for (const uId in users) {
          const u = users[uId];
          if (u._data.isMe) {
            if (!self.state.playerInitialized) {
              if (u._data.state.playerNumber !== undefined) {
                //const spawner = rs.scene.getObjectByName("PlayerSpawner" + ((u._data.state.playerNumber % 2) + 1));
                let spawnPos = new THREE.Vector3(0, 0, 0);
                rs.playerCollider.start.set(0, 0.35, 0);
                rs.playerCollider.end.set(0, 1.7, 0);
                rs.playerCollider.radius = 0.35;
                rs.playerCollider.translate(spawnPos);
                rs.camera.position.copy(rs.playerCollider.end);
                rs.camera.rotation.set(0, 0, 0);
                self.setState({ playerInitialized: true });
              }
            }
            continue;
          }
        }
      });

      getRenderService().addCallback("sceneupdate", (scene, rs) => {
        self.updateWorldState(getDataService().getGameState(), getDataService());
      });
      getDataService().addCallback("gamestate", self.updateWorldState);
    }
  }

  updateWorldState(gameState, ds) {
    this.setState({ gamestate: gameState });

    if (gameState["Temple_Room_0_Wall_0"] === undefined) {
      getDataService().updateGameState({ Temple_Room_0_Wall_0: 0, Temple_Room_0_Wall_1: 0, Temple_Room_0_Wall_2: 0, Temple_Counter_1: 0, Temple_Counter_2: 0, Temple_Counter_3: 0, Temple_Maze_A: 1, Temple_Maze_B: 1, Temple_Maze_C: 1 });
    }

    let recalculate = false;

    if (this.state.numberOfHints != gameState.numberOfHints) {
      this.setState({ numberOfHints: gameState.numberOfHints });
    }

    const self = this;
    let dialogClose = () => {
      document.body.requestPointerLock();
      getRenderService().enableKeyboardControls = true;
      self.setState({ combinationlock: undefined, book: undefined, inspect: undefined, finalDialog: undefined });
    };

    if (this.state.gamestate) {
      if (!self.lastGameState) {
        self.lastGameState = { laser1: 0, laser2: 0, laser3: 0 };
      }
      if (self.lastGameState) {
        if (self.lastGameState["Temple_Room_0_Wall_0"] != this.state.gamestate["Temple_Room_0_Wall_0"]) {
          getSoundService().playSound("Temple_Room0_Door_1");
        }
        if (self.lastGameState["Temple_Room_0_Wall_1"] != this.state.gamestate["Temple_Room_0_Wall_1"]) {
          getSoundService().playSound("Temple_Room0_Door_2");
        }
        if (self.lastGameState["Temple_Room_0_Wall_2"] != this.state.gamestate["Temple_Room_0_Wall_2"]) {
          getSoundService().playSound("Temple_Room0_Door_3");
        }
      }

      if (this.state.gamestate["laser1"]) {
        getSoundService().playSound("Temple_Laser_1", { noStop: true, loop: true });
      }
      if (this.state.gamestate["laser2"]) {
        getSoundService().playSound("Temple_Laser_2", { noStop: true, loop: true });
      }
      if (this.state.gamestate["laser3"]) {
        getSoundService().playSound("Temple_Laser_3", { noStop: true, loop: true });
      }

      if (this.state.gamestate["Temple_Room_0_Wall_0"] == 1 && this.state.gamestate["Temple_Room_0_Wall_1"] == 1 && this.state.gamestate["Temple_Room_0_Wall_2"] == 1 && this.state.gamestate["Temple_Door0"] != 1) {
        let new_state = {};
        new_state["Temple_Door0"] = 1;
        getDataService().updateGameState(new_state);
      }

      if (this.state.gamestate["Temple_Counter_1"] == 3 && this.state.gamestate["Temple_Counter_2"] == 3 && this.state.gamestate["Temple_Counter_3"] == 1 && this.state.gamestate["laser2"] != 1) {
        let new_state = {};
        new_state["laser2"] = 1;
        getDataService().updateGameState(new_state);
      }

      if (this.state.gamestate["laser1"] >= 0) {
        getRenderService().scene.getObjectByName("Temple_Laser_1").visible = true;
      }

      if (this.state.gamestate["laser2"] >= 0) {
        getRenderService().scene.getObjectByName("Temple_Laser_2").visible = true;
      }

      if (this.state.gamestate["laser3"] >= 0) {
        getRenderService().scene.getObjectByName("Temple_Laser_3").visible = true;
      }
      if (this.state.gamestate["finalDoor"] === 1) {
        getRenderService().scene.getObjectByName("Temple_Laser_4").visible = true;
      }
    }



    if (this.state.combinationlock) {

      let selection = undefined;
      if (this.state.selectedObject) {
        let obj = this.getInteractableParent(this.state.selectedObject.object);
        selection = obj.name;
        console.log(selection);
      }
    }

    let right = 0; let halfright = 0;
    if (gameState["mm1"] >= 1 && gameState["mm2"] >= 1 && gameState["mm3"] >= 1) {
      let solutionString = gameState["mm1"] + "" + gameState["mm2"] + gameState["mm3"];
      right = (solutionString.charAt(0) == "2" ? 1 : 0) + (solutionString.charAt(1) == "6" ? 1 : 0) + (solutionString.charAt(2) == "4" ? 1 : 0);
      halfright = (solutionString.indexOf("2") >= 0 ? 1 : 0) + (solutionString.indexOf("6") >= 0 ? 1 : 0) + (solutionString.indexOf("4") >= 0 ? 1 : 0);
    }

    if (right == 3 && this.state.gamestate["laser1"] != 1) {
      let new_state = {};
      new_state["laser1"] = 1;
      getDataService().updateGameState(new_state);
    }

    if (this.state.gamestate["laser1"] === 1 && this.state.gamestate["laser2"] === 1 && this.state.gamestate["laser3"] === 1) {
      if (!this.state.gamestate["successTime"]) {
        getDataService().updateGameState({ successTime: (new Date()).getTime(), finalDoor: 1 });
      }
    }

    getRenderService().scene.traverse((element) => {

      if (element.name && element.name == "Temple_Maze_Wall_0") {
        //if (gameState.Temple_Maze_A > 0 == element.visible) {
        element.visible = !(gameState.Temple_Maze_A > 0);
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //recalculate = true;
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_1") {
        //if (gameState.Temple_Maze_C > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_C > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_2") {
        //if (gameState.Temple_Maze_C > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_C > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_3") {
        //if (gameState.Temple_Maze_A > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_A > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_4") {
        //if (gameState.Temple_Maze_B > 0 == element.visible) {
        element.visible = !(gameState.Temple_Maze_B > 0);
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_5") {
        //if (gameState.Temple_Maze_B > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_B > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_6") {
        //if (gameState.Temple_Maze_B > 0 == element.visible) {
        element.visible = !(gameState.Temple_Maze_B > 0);
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_7") {
        //if (gameState.Temple_Maze_C > 0 == element.visible) {
        element.visible = !(gameState.Temple_Maze_C > 0);
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }


      if (element.name && element.name == "Temple_Maze_Wall_8") {
        //if (gameState.Temple_Maze_A > 0 == element.visible) {
        element.visible = !(gameState.Temple_Maze_A > 0);
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_9") {
        //if (gameState.Temple_Maze_B > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_B > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_10") {
        //if (gameState.Temple_Maze_C > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_C > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_11") {
        //if (gameState.Temple_Maze_B > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_B > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_Maze_Wall_12") {
        //if (gameState.Temple_Maze_A > 0 != element.visible) {
        element.visible = gameState.Temple_Maze_A > 0;
        if (element.visible) {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
            element.no_collision = false;
            oct.fromGraphNode(element);
            element.no_collision = true;
          }
        } else {
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
        //}
      }

      if (element.name && element.name == "Temple_MainRoom_Final_Door") {
        if (gameState.finalDoor > 0 && element.visible) {
          element.visible = false;
          let oct = getRenderService().getOctree(element.name);
          if (oct) {
            oct.clear();
          }
        }
      }

      if (element.name && element.name == "Temple_MaterSign1") {
        if (right >= 1) {
          element.material.color.setHex(0x00ff00);
        } else if (halfright >= 1) {
          element.material.color.setHex(0xffffff);
        } else {
          element.material.color.setHex(0x000000);
        }
        element.material.needsUpdate = true;
      }
      if (element.name && element.name == "Temple_MaterSign2") {
        if (right >= 2) {
          element.material.color.setHex(0x00ff00);
        } else if (halfright >= 2) {
          element.material.color.setHex(0xffffff);
        } else {
          element.material.color.setHex(0x000000);
        }
        element.material.needsUpdate = true;
      }
      if (element.name && element.name == "Temple_MaterSign3") {
        if (right >= 3) {
          element.material.color.setHex(0x00ff00);
        } else if (halfright >= 3) {
          element.material.color.setHex(0xffffff);
        } else {
          element.material.color.setHex(0x000000);
        }
        element.material.needsUpdate = true;
      }

      const colorMap = { 0: 0xffffff, 1: 0xC22DAC, 2: 0xA98344, 3: 0x111111, 4: 0x29AE83, 5: 0x1F6C9F, 6: 0x9F1F3F };
      if (element.name && element.name == "Temple_Mastermind_Display_1") {
        if (gameState["mm1"] >= 1) {
          element.material.color.setHex(colorMap[gameState["mm1"]]);
        } else {
          element.material.color.setHex(0xffffff);
        }
        element.material.needsUpdate = true;
      }
      if (element.name && element.name == "Temple_Mastermind_Display_2") {
        if (gameState["mm2"] >= 1) {
          console.log("changing color 2 to ", gameState["mm2"]);
          element.material.color.setHex(colorMap[gameState["mm2"]]);
        } else {
          element.material.color.setHex(0xffffff);
        }
        element.material.needsUpdate = true;
      }
      if (element.name && element.name == "Temple_Mastermind_Display_3") {
        if (gameState["mm3"] >= 1) {
          console.log("changing color 3 to ", gameState["mm3"]);
          element.material.color.setHex(colorMap[gameState["mm3"]]);
        } else {
          element.material.color.setHex(0xffffff);
        }
        element.material.needsUpdate = true;
      }

      if (element.name && element.name == "Temple_Room0_Wall" && gameState["Temple_Door0"] == 1) {
        getRenderService().removeOctree(element.name);
      }


      if (element.name && element.name == "Temple_MainRoom_Lock") {
        if (gameState.lock1) {
          element.visible = false;
          element.no_collision = true;
          getRenderService().removeOctree("Temple_MainRoom_Lock");
        }
      }
      if (element.name && element.name == "Temple_MainRoom_Door") {
        if (gameState.lock1) {
          element.visible = false;
          element.no_collision = true;
          getRenderService().removeOctree("Temple_MainRoom_Door");
        }
      }

      if (element.name && element.name == "Door5") {
        const tarRot = Math.PI;
        if (gameState.lock5 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          recalculate = true;

          if (!gameState["successTime"]) {
            getDataService().updateGameState({ successTime: (new Date()).getTime() });
          }
        }
      }
    });

    if (recalculate) {
      getRenderService().worldOctree.clear();
      getRenderService().worldOctree.fromGraphNode(getRenderService().scene);
    }

    self.lastGameState = JSON.parse(JSON.stringify(gameState));
  }

  tickScene(tick, rs) {

  }

  getInteractableParent(obj) {
    for (let i = 0; i < 10; i++) {
      if (!this.interactions[obj.name]) {
        if (obj.parent && obj.parent.name != "Scene") {
          obj = obj.parent;
        } else {
          break;
        }
      } else {
        break;
      }
    }
    return obj;
  }

  render() {
    const self = this;
    let selection = undefined;
    if (this.state.selectedObject) {
      let obj = this.getInteractableParent(this.state.selectedObject.object);
      selection = obj.name;
      console.log(selection);
    }

    let gameState = getDataService().getGameState();
    if (gameState.successTime && !this.state.finalDialogShown && !window.finalDialogTimer) {
      window.finalDialogTimer = window.setTimeout(() => {
        document.exitPointerLock();
        self.setState({ finalDialog: true, finalDialogShown: true });
      }, 5000);

    }

    let dialogClose = () => {
      document.body.requestPointerLock();
      getRenderService().enableKeyboardControls = true;
      self.setState({ combinationlock: undefined, book: undefined, inspect: undefined, finalDialog: undefined });
    };

    let combinationLockResult = (numbers) => {
      dialogClose();
      switch (this.state.selectedObject.object.name) {
        case "Temple_MainRoom_Lock":
          if (numbers[0] == 2 && numbers[1] == 5 && numbers[2] == 3) {
            getDataService().updateGameState({ lock1: 1 });
            return true;
          }
          break;
      }
      return false;
    };

    let combinationlock = "";
    if (this.state.combinationlock) {
      combinationlock = <CombinationLock selectedObject={selection} onResult={combinationLockResult} onClose={dialogClose} />;
    }

    let book = "";
    if (this.state.book) {
      book = <BookDialog selectedObject={selection} onResult={dialogClose} onClose={dialogClose} />;
    }
    let cursor = [];
    if (this.state.pointerLock) {
      let translation = getTranslationService().translate(selection);

      let interaction = "";
      let currentInteraction = self.interactions[selection]
      if (currentInteraction) {
        interaction = [<br />,
        <div className={"tag is-link"}>
          {getTranslationService().translate(currentInteraction)}
        </div>]
      }

      cursor = [
        <div className={"cursor " + (this.state.selectedObject ? " cursor-hover " : "")}></div>];
      //if(getTranslationService().translate(selection)){
      cursor.push(<div key="cursor" style={{ width: "100%" }} className={"selection " + (selection && translation ? "selection-hover" : "")}>
        <div className={"tag is-dark"}>
          {translation}
        </div>
        {interaction}
      </div>);

      cursor.push(<div className='bottom-right-hint has-text-white'>
        <table >
          <tbody>
            <tr>
              <td className='has-text-right'><span className='keyboard-key'>Esc</span></td>
              <td className='pl-2'>{getTranslationService().translate("Menü")}</td>
            </tr>
            <tr>
              <td className='has-text-right pt-2'><span className='keyboard-key'>W</span><span className='keyboard-key'>A</span><span className='keyboard-key'>S</span><span className='keyboard-key'>D</span></td>
              <td className='pl-2 pt-2'>{getTranslationService().translate("Bewegen")}</td>
            </tr>
            <tr>
              <td className='has-text-right  pt-2' ><span className='keyboard-key' style={{ width: "6rem" }}>{getTranslationService().translate("Leertaste")}</span></td>
              <td className='pl-2 pt-2'>{getTranslationService().translate("Springen")}</td>
            </tr>
          </tbody>
        </table>
      </div>);
      //}
    } else {
      const gamestate = this.state.gamestate;
      const hints = [];
      if (this.state.gamestate) {
        // open first door
        hints.push({ hint: getTranslationService().translate("room3_hint_1_1"), solved: gamestate.Temple_Door0 });
        hints.push({ hint: getTranslationService().translate("room3_hint_1_2"), solved: gamestate.Temple_Door0 });
        hints.push({ hint: getTranslationService().translate("room3_hint_1_3"), solved: gamestate.Temple_Door0 });
        hints.push({ hint: getTranslationService().translate("room3_hint_1_4"), solved: gamestate.Temple_Door0 });
        // open second door
        hints.push({ hint: getTranslationService().translate("room3_hint_2_1"), solved: gamestate.laser2 });
        hints.push({ hint: getTranslationService().translate("room3_hint_2_2"), solved: gamestate.laser2 });
        hints.push({ hint: getTranslationService().translate("room3_hint_2_3"), solved: gamestate.laser2 });
        hints.push({ hint: getTranslationService().translate("room3_hint_2_4"), solved: gamestate.laser2 });
        // open third door
        hints.push({ hint: getTranslationService().translate("room3_hint_3_1"), solved: gamestate.laser3 });
        hints.push({ hint: getTranslationService().translate("room3_hint_3_2"), solved: gamestate.laser3 });
        hints.push({ hint: getTranslationService().translate("room3_hint_3_3"), solved: gamestate.laser3 });


        hints.push({ hint: getTranslationService().translate("room3_hint_4_1"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_4_2"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_4_3"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_4_3"), solved: gamestate.lock1 });

        // open final door
        hints.push({ hint: getTranslationService().translate("room3_hint_5_1"), solved: gamestate.laser1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_5_2"), solved: gamestate.laser1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_5_3"), solved: gamestate.laser1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_5_4"), solved: gamestate.laser1 });
        hints.push({ hint: getTranslationService().translate("room3_hint_5_5"), solved: gamestate.laser1 });

      }
      if (!(this.state.combinationlock || this.state.book || this.state.cameraFocus || this.state.inspect)) {
        cursor = <MenuOverlay onClose={dialogClose} gamestate={gamestate} hints={hints} onShowMessage={() => { self.setState({ finalDialog: true }); document.exitPointerLock(); }} />;
      }
    }


    let tableView = "";
    if (this.state.cameraFocus) {
      tableView = <TableView onLeave={() => {
        this.setState({ cameraFocus: undefined });
        getRenderService().freeCamera = false;
        getRenderService().enablePointerLock = true;
        document.body.requestPointerLock();
        getRenderService().camera.rotation.x = 0;
        getRenderService().camera.rotation.z = 0;
      }} />;
    }

    let inspect = "";
    if (this.state.inspect) {
      inspect = <InspectThreeDialog selectedObject={this.state.inspect} onClose={dialogClose} />
    }

    getRenderService().enableKeyboardControls = !(this.state.cameraFocus || this.state.book || this.state.inspect || this.state.combinationlock);
    // 
    return (
      <div className="App">
        <div style={{ position: "fixed", left: 0, bottom: 0, zIndex: 100 }} key="musicAttribution">Music by <a href="https://www.youtube.com/c/AlexandrZhelanovsMusic" target="_blank">Alexandr Zhelanov</a> <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY 4.0</a></div>
        {combinationlock}
        {book}
        {tableView}
        {inspect}
        <PlayerRepresentation autoupdate={1} />
        <Timer />
        <Notifications />
        {this.state.finalDialog ? <FinalDialog onClose={dialogClose} /> : cursor}
      </div>
    );
  }

}

export default Room3;
