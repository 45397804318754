class User {
    constructor(data) {
        this._data = data;
        this._localState = {};
        this._eventListeners = {
            "update_state": [],
            "update_event": []
        }
        if(data.id == Crowded._playerId){
            this._data.isMe = 1;
        }else{
            this._data.isMe = 0;
        }
    }

    addEventListener(event, listener) {
        if (!this._eventListeners[event]) {
            console.log("Unknown event type: " + event, "No listener was registered.");
        } else {
            this._eventListeners[event].push(listener);
        }
    }

    updateName(newName) {
        if (this._data.isMe) {
            this._data.name = newName;
            Crowded._sendMessage({ type: "system", event: "user_response", data: this._data });
        }
    }

    updateState(newState) {
        Crowded._sendMessage({ type: "game", event: "user_update_state", data: { state: newState, userId: this._data.id } });
    }

    getState() {
        return this._data.state;
    }

    updateLocalState(newState) {
        this._localState = Object.assign(this._localState, newState);
    }

    getLocalState() {
        return this._localState;
    }

    updateEvent(event) {
        Crowded._sendMessage({ type: "game", event: "user_update_event", data: { event: event, userId: this._data.id } });
    }

    _updateState(newState) {
        this._data.state = Object.assign(this._data.state, newState);
        for (let cb of this._eventListeners["update_state"]) {
            cb(newState);
        }
    }

    _updateEvent(event) {
        for (let cb of this._eventListeners["update_event"]) {
            cb(event);
        }
    }
}

export class Crowded {
    static _eventListeners = {
        "initialize": [],
        "user_new": [],
        "game_update_state": [],
        "game_update_event": [],
        "user_update_state": [],
        "sync": [],
        "user_update_event": []
    }
    static _users = {};
    static _game = { state: {} };

    static ReadyForCommunication(initializeListener) {
        if (initializeListener) {
            Crowded.addEventListener("initialize", initializeListener);
        }
        const url_params = new URLSearchParams(window.location.search);
        const backendIp = url_params.get("ip");
        Crowded._playerId = window.localStorage.getItem("global_player_id");
        if(Crowded._playerId === null){
            Crowded._playerId = Math.round(Math.random() * 999999999)+"";
            window.localStorage.setItem("global_player_id", Crowded._playerId);
        }
        if(window.location.protocol.indexOf("https")<0){
        this.ws = new WebSocket("ws://" + backendIp + "?playerId=" + Crowded._playerId);

        const self = this;
        this.ws.onopen = () => { 
            console.log("Connection opened");
            for (let cb of Crowded._eventListeners["initialize"]) {
                cb();
            }
        };

        this.ws.onclose = (event) => { 
            if(event.code != 1000){
                window.setTimeout(()=>Crowded.ReadyForCommunication(), 1000);
            }
            console.log("Connection closed", event); };
            //this.ws.close();
        this.ws.onerror = (error) => { 
            this.ws.close();
            console.log('WebSocket Error ', error);
            //window.setTimeout(()=>Crowded.ReadyForCommunication(initializeListener), 1000); 
        };

        // Log messages from the server
        this.ws.onmessage = (e) => {
            const data = JSON.parse(e.data);
            Crowded._parseMessage(data);
        };
        }
    }

    static addEventListener(event, listener) {
        if (!Crowded._eventListeners[event]) {
            console.log("Unknown event type: " + event, "No listener was registered.");
        } else {
            Crowded._eventListeners[event].push(listener);
        }
    }

    static getUsers(includeScreens = false) {
        if (includeScreens) {
            return Crowded._users;
        }
        let res = {};
        for (const key in Crowded._users) {
            if (!Crowded._users[key]._data.isScreen) {
                res[key] = Crowded._users[key];
            }
        }
        return res;
    }

    static getGameState() {
        return this._game.state;
    }

    static updateGameState(state) {
        Crowded._sendMessage({ type: "game", event: "game_update_state", data: state });
    }

    static triggerGameEvent(event) {
        Crowded._sendMessage({ type: "game", event: "game_update_event", data: event });
    }

    static isScreen() {
        for (let userId in Crowded._users) {
            const user = Crowded._users[userId];
            if (user._data.isMe) {
                return user._data.isScreen > 0;
            }
        }
    }

    static getMe() {
        for (let userId in Crowded._users) {
            const user = Crowded._users[userId];
            if (user._data.isMe) {
                return user;
            }
        }
        return new User({state:{}});
    }

    static _parseMessage(e) {
        if (e.type === "system") {
            switch (e.event) {
                case "sync":
                    for (let userId in e.data.users) {
                        const user = e.data.users[userId];
                        const newUser = !Crowded._users[user.id];
                        if (newUser) {
                            Crowded._users[user.id] = new User(user);
                        } else {
                            Crowded._users[user.id]._data = Object.assign(Crowded._users[user.id]._data, user);
                        }
                        console.log("users", Crowded._users);
                        if (newUser) {
                            // new user event
                            const new_user_event = { type: "system", event: "user_new", data: user };
                            for (let cb of Crowded._eventListeners["user_new"]) {
                                cb(new_user_event);
                            }
                        }
                    }
                    Crowded._game = Object.assign(Crowded._game, e.data.game);
                    for (let cb of Crowded._eventListeners["sync"]) {
                        cb(e);
                    }
                    break;

            }
        }
        if (e.type === "game") {
            switch (e.event) {
                case "initialize":
                    for (const user in e.data.users) {
                        Crowded._parseMessage({ type: "system", event: "user_response", data: e.data.users[user] });
                    }
                    for (let cb of Crowded._eventListeners["initialize"]) {
                        cb(e);
                    }
                    break;
                case "game_update_state":
                    Crowded._game.state = Object.assign(Crowded._game.state, e.data);
                    for (let cb of Crowded._eventListeners["game_update_state"]) {
                        cb(e);
                    }
                    break;
                case "game_update_event":
                    for (let cb of Crowded._eventListeners["game_update_event"]) {
                        cb(e);
                    }
                    break;
                case "user_update_state":
                    const userId = e.data.userId;
                    if (!Crowded._users[userId]) {
                        Crowded._users[userId] = new User({id: userId, state: {}});;
                    }
                    Crowded._users[userId]._updateState(e.data.state);
                    for (let cb of Crowded._eventListeners["user_update_state"]) {
                        cb(e);
                    }
                    break;
                case "user_update_event":
                    const userId2 = e.data.userId;
                    Crowded._users[userId2]._updateEvent(e.data.event)
                    for (let cb of Crowded._eventListeners["user_update_event"]) {
                        cb(e);
                    }
                    break;
            }
        }
    }
    static _sendMessage(msg) {
        if(this.ws && this.ws.readyState == 1){ // OPEN
            this.ws.send(JSON.stringify(msg));
        }
    }
}

