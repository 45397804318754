import logo from './logo.svg';
import './Lobby.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from './framework/ThreeRenderService'
import CombinationLock from './framework/CombinationLock';
import { getDataService } from './framework/DataService';
import BookDialog from './framework/BookDialog';
import Notifications from './framework/Notifications';
import Timer from './framework/Timer';
import * as THREE from 'three';
import TableView from './framework/TableView';
import PlayerRepresentation from './framework/PlayerRepresentation';
import { getTranslationService } from './TranslationService';
import InspectThreeDialog from './framework/InspectThreeDialog';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Navigate } from 'react-router-dom';
import LanguageSelector from './framework/LanguageSelector';

class Lobby extends Component {
  constructor(props) {
    super(props);
    getRenderService().addToDom("three-container");
    getRenderService().freeCamera = true;
    getRenderService().enablePointerLock = false;
    getRenderService().enableKeyboardControls = false;
    this.mounted = false;

    this.state = { users: {}, connected: false, loadedCounter: 0, longTermProgressBar: 0 };

    this.updateState = this.updateState.bind(this);
    this.makeMeReady = this.makeMeReady.bind(this);
    this.changeName = this.changeName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.roomChange = this.roomChange.bind(this);
    this.outfitChange = this.outfitChange.bind(this);

  }

  componentDidMount() {

    getDataService().addCallback("sceneupdate", this.updateState);
    getDataService().addCallback("gamestate", () => {
      if (!this.state.connected) {
        this.setState({ connected: true });
        console.log("Lobby registered connection.")
      }
      this.updateState();
    }
    );
    getDataService().addCallback("userstate", this.updateState);


    if (this.mounted) {
      return;
    }


    setInterval(this.updateState, 1000);
    this.mounted = true;
    const self = this;

    let increaseProgress = () => {
      self.setState({longTermProgressBar: self.state.longTermProgressBar+1});
      window.setTimeout(increaseProgress, Math.pow(1.3,self.state.longTermProgressBar/2)+100);
    };
    increaseProgress();


    getRenderService().camera.position.z = 2.3;
    getRenderService().camera.position.x = -0.1;
    getRenderService().camera.position.y = 1.6;
    getRenderService().camera.rotation.y = 0;

    const loader = new GLTFLoader();
    this.dungeonScene = undefined;
    this.spaceScene = undefined;
    this.templeScene = undefined;
    this.loadedCounter = 0;

    loader.load('/temple_scene_lobby.glb', (gltf) => {
      self.setState({ loadedCounter: ++this.loadedCounter });
      console.log("loaded temple");

      this.templeScene = gltf.scene;
      getRenderService().scene.add(gltf.scene);
    });


    loader.load('/space_scene_lobby.glb', (gltf) => {
      self.setState({ loadedCounter: ++this.loadedCounter });
      console.log("loaded space");

      this.spaceScene = gltf.scene;
      getRenderService().scene.add(gltf.scene);

      gltf.scene.traverse(child => {
        if (child.type == "PointLight") {
          child.intensity /= 400;
        }
        if (child.name === "Chest_Open") {
          child.visible = false;
        }
        if (child.name === "Scroll_Chest") {
          child.visible = false;
        }
        if (child.isMesh) {
          if (child.material) {
            child.material.roughness = 1;
            child.material.metalness = 0;
          }
        }

      });

      loader.load('/test_dungeon_scene_lobby.glb', (gltf) => {
        console.log("loaded dungeon");
        self.setState({ loadedCounter: ++this.loadedCounter });

        this.dungeonScene = gltf.scene;
        getRenderService().scene.add(gltf.scene);

        gltf.scene.traverse(child => {
          if (child.type == "PointLight") {
            child.intensity /= 400;
          }
          if (child.name === "Chest_Open") {
            child.visible = false;
          }
          if (child.name === "Scroll_Chest") {
            child.visible = false;
          }
          if (child.isMesh) {
            if (child.material) {
              child.material.roughness = 1;
              child.material.metalness = 0;
            }
          }

        });

        getRenderService().worldOctree.fromGraphNode(gltf.scene);
        getRenderService().animate();

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);
        let lobbyTargetPos = new THREE.Vector3(0, 0, 0);
        getRenderService().addCallback("scenetick", (timediff, rs) => {
          var camPos = rs.camera.position.clone();       // Holds current camera position
          const numberOfPlayers = Object.values(this.state.users).filter(u => !u._data.offline).length;
          lobbyTargetPos.x = -0.1 + ((numberOfPlayers - 1) * 0.95 * 0.5);
          lobbyTargetPos.y = rs.camera.position.y;
          lobbyTargetPos.z = rs.camera.position.z;


          // Interpolate camPos toward targetPos
          rs.camera.position.lerp(lobbyTargetPos, 3 * timediff);
        });

      });

    });

  }

  makeMeReady() {
    const user = getDataService().getMe();
    user.updateState({ ready: !user._data.state.ready });
  }

  changeName(newName) {
    const user = getDataService().getMe();
    user.updateState({ name: newName });
  }

  startGame() {
    let state = getDataService().getGameState();
    if (state.room == "Space") {
      getDataService().updateGameState({ page: "/room2/" + window.location.search, startTime: (new Date()).getTime() });
    } else if (state.room == "Dungeon") {
      getDataService().updateGameState({ page: "/room/" + window.location.search, startTime: (new Date()).getTime() });
    } else {
      getDataService().updateGameState({ page: "/room3/" + window.location.search, startTime: (new Date()).getTime() })
    }
  }

  updateState() {
    let state = getDataService().getGameState();
    if (state.page && window.location.pathname !== state.page) {
      window.location.href = state.page;
    }

    if (!state.room) {
      getDataService().updateGameState({ room: "Dungeon" });
      state.room = "Dungeon";
    }

    if (this.dungeonScene && this.spaceScene) {
      if (state.room == "Dungeon") {
        this.dungeonScene.visible = true;
        this.spaceScene.visible = false;
        this.templeScene.visible = false;
      } else if (state.room == "Space") {
        this.dungeonScene.visible = false;
        this.spaceScene.visible = true;
        this.templeScene.visible = false;
      } else {
        this.dungeonScene.visible = false;
        this.spaceScene.visible = false;
        this.templeScene.visible = true;
      }
    }

    let users = getDataService().getUsers();

    this.setState({ users: users });
    let name = "";
    let me = getDataService().getMe();
    if (me && me._data && me._data.state && me._data.state.name) {
      name = me._data.state.name;
    }
    if (me && me._data) {
      const playerNumber = getDataService().getPlayerNumber(me._data.id);
      let statePos = { x: playerNumber * 0.95, y: 1.6, z: -0.5 };
      if (me._data.state && me._data.state.setPosition  != playerNumber + 1) {
        me.updateState({ pos: statePos, rot: { x: 0, y: Math.PI, z: 0 }, setPosition: playerNumber + 1 });
      }
    }
    this.setState({ name: name });
  }

  handleChange(event) {
    this.setState({ name: event.target.value });
    this.changeName(event.target.value);
  }

  outfitChange(event) {
    let outfit = parseInt(event.target.value);
    this.setState({ outfit: outfit });
    const user = getDataService().getMe();
    user.updateState({ outfit: outfit });
  }

  roomChange(event) {
    this.setState({ room: event.target.value });
    getDataService().updateGameState({ room: event.target.value })
  }

  render() {
    const self = this;

    let canStartGame = Object.values(this.state.users).filter(u => !u._data.offline).length > 1;
    let players = [];
    let readyPlayers = 0;

    for (let p in this.state.users) {
      let user = this.state.users[p];

      console.log(user._data.offline);
      if (user._data.offline) {
        continue;
      }

      if (!user._data.state.ready) {
        if(readyPlayers<2){
          canStartGame = false;
        }
      }else{
        readyPlayers++;
        if(readyPlayers>=2){
          canStartGame = true;
        }
      }

      if (user._data.isMe) {
        players.unshift(<div key={"user_state_" + user._data.id} className={"card playerCard " + (user._data.state.ready ? "has-background-success" : " ")}>
          <div className="card-content">
            <div className="content">
              <div className="field">
                <label className="label">{getTranslationService().translate("lobby_name")}</label>
                <div className="control">
                  <input className="input" type="text" maxLength={20} value={this.state.name} onChange={this.handleChange} placeholder={getTranslationService().translate("lobby_enter_name")} />
                </div>
              </div>
              <div className="control">
                <div className="select is-fullwidth">
                  <select onChange={this.outfitChange} defaultValue={!user._data.state.outfit?1:user._data.state.outfit}>
                    <option value="1">Outfit 1</option>
                    <option value="2">Outfit 2</option>
                    <option value="3">Outfit 3</option>
                    <option value="4">Outfit 4</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
          <footer className="card-footer ">
            <a href="#" className="card-footer-item" onClick={this.makeMeReady}>{user._data.state.ready ? getTranslationService().translate("lobby_is_ready") + " ✔" : getTranslationService().translate("lobby_make_ready")}</a>
          </footer>
        </div>);
      } else {
        players.push(<div key={"user_state_" + user._data.id} className={"card playerCard " + (user._data.state.ready ? "has-background-success" : " has-background-light")}>
          <div className="card-content">
            <div className="content">
              <p className="title is-4">{user._data.state.name}</p>
              <p className="subtitle is-6">{user._data.state.ready ? getTranslationService().translate("lobby_ready") : getTranslationService().translate("lobby_not_ready")}</p>
            </div>
          </div>
        </div>);
      }

    }

    let connected = "";
    if (!this.state.connected || this.state.loadedCounter < 3) {
      connected = <div className="loader-wrapper">
      <p className="title is-4">Loading...</p>
      <p className="subtitle is-6">This might take a while </p>
        <div id="loaderSpinner" className="loader is-loading"></div>
        <p className="title is-3" style={{"position": "relative", top: "-60px"}}>{self.state.longTermProgressBar}% </p>
        <p >Rooms loaded: {this.state.loadedCounter ? this.state.loadedCounter : 0} </p>

      </div>;
    }

    return (
      <div key="main_container" className='mainContainer'>
        {connected}
        <PlayerRepresentation drawMe={1} />
        
        <div className='playerContainer'>
          {players}
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div className='lobby-center'>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div className='lobby-right' style={{ marginRight: "2rem" }}>

          <div className='connectionShare '>
            <div className="card has-background-info-light has-text-info mb-4">
              <div className="card-content">
                <div className="content">
                  <div className="field">
                    <p className="">{getTranslationService().translate("lobby_share")}</p>
                    <div className="control">
                      <input className='input' onClick={(event) => { event.target.setSelectionRange(0, 99999); navigator.clipboard.writeText(window.location.href); this.setState({ clipboard: true }) }} value={window.location.href} readOnly></input>
                      {this.state.clipboard ? <p className="help">{getTranslationService().translate("lobby_copied_url")}</p> : ""}
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div className="card mb-4">
              <div className="card-content">
                <div className="content">
                  <div className="field">
                    <label className="label">{getTranslationService().translate("lobby_escape_room")}</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select onChange={this.roomChange} defaultValue={getDataService().getGameState().room}>
                          <option value="Dungeon">{getTranslationService().translate("lobby_room1")}</option>
                          <option value="Space">{getTranslationService().translate("lobby_room2")}</option>
                          <option value="Temple">{getTranslationService().translate("lobby_room3")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

                    <LanguageSelector inline={true}></LanguageSelector>
          </div>

          <div style={{ flexGrow: 1 }}></div>
          <div className="card startGameButton ">
            <div className="card-content">
              <div className="content">
                {canStartGame ? "" : <p>{getTranslationService().translate("lobby_start_game_info")}</p>}
                <button className='button is-fullwidth is-primary is-large' style={{backgroundColor: "rgb(38, 148, 99)"}} disabled={!canStartGame} onClick={this.startGame}>{getTranslationService().translate("lobby_start_game")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Lobby;
