import './LandingPage2.css';
import '../App.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from '../framework/ThreeRenderService'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaw, faCube, faLayerGroup, faEarthAmericas, faPeopleGroup, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faChrome } from '@fortawesome/free-brands-svg-icons'

import { getTranslationService } from '../TranslationService';
import LanguageSelector from '../framework/LanguageSelector';
import PlayerRepresentation from '../framework/PlayerRepresentation';
import { getDataService } from '../framework/DataService';
import { Crowded } from '../crowded';
import { faClock } from '@fortawesome/free-regular-svg-icons';

class LandingPage2 extends Component {
  constructor(props) {
    super(props);


    this.animations = {};
    this.outfits = {};
    getRenderService();
    getRenderService().freeCamera = true;
    getRenderService().enablePointerLock = false;
    getRenderService().enableKeyboardControls = false;
    this.mounted = false;

    this.state = { users: {}, connected: false, loading: false, session_key: "", burger: false };

    this.loadOutfit = this.loadOutfit.bind(this);
    this.createPlayerIfLoaded = this.createPlayerIfLoaded.bind(this);
    this.animationPlayback = this.animationPlayback.bind(this);
  }

  createPlayerIfLoaded() {
  }

  componentDidMount() {

    if (this.mounted) {
      return;
    }


    getRenderService().addToDom("three-container-hero");

    this.mounted = true;
    const self = this;

    window.onmousemove = function (e) {
      let x = (e.clientX / window.innerWidth - 0.5) * 4;
      let y = e.clientY / window.innerHeight - 0.5;
      getRenderService().camera.position.z = 3.2;
      getRenderService().camera.position.x = (getRenderService().camera.position.x * 10 + (2.0 + x)) / 11;
      getRenderService().camera.position.y = 1.6;
      //getRenderService().camera.rotation.y = 0;
      //getRenderService().camera.rotation.x = -0.22;
      getRenderService().camera.lookAt(2.00, 1, 0);
    }

    function handleOrientation(event) {
      const alpha = event.alpha;
      const beta = event.beta;
      const gamma = event.gamma;
      let x = gamma / 180 * 2;
      getRenderService().camera.position.z = 3.2;
      getRenderService().camera.position.x = 2.0 + x;
      getRenderService().camera.position.y = 1.6;
      //getRenderService().camera.rotation.y = 0;
      //getRenderService().camera.rotation.x = -0.22;
      getRenderService().camera.lookAt(2.00, 1, 0);
    }

    window.addEventListener('deviceorientation', handleOrientation);


    setTimeout(this.createPlayerIfLoaded, 1000);


    getRenderService().camera.position.z = 3.2;
    getRenderService().camera.position.x = 2.0;
    getRenderService().camera.position.y = 1.6;
    getRenderService().camera.rotation.y = 0;
    getRenderService().camera.rotation.x = -0.22;

    const loader2 = new FBXLoader();
    loader2.load('/stickfigure_3.fbx', (fbx) => {
      //fbx = fbx.children[0];
      fbx.scale.setScalar(0.011);
      fbx.traverse(child => {
        if (child.isMesh) child.geometry.computeVertexNormals()
      });

      self.loadOutfit('/stickfigure_2_uv_char1.png', 3);
      self.loadOutfit('/stickfigure_2_uv_char2.png', 2);
      self.loadOutfit('/stickfigure_2_uv_char3.png', 4);
      self.loadOutfit('/stickfigure_2_uv_char4.png', 1);

      self.playerPrefab = fbx;

      const animIdle = new FBXLoader();
      animIdle.load('/Anim_Idle.fbx', (anim) => {
        self.animations["idle"] = anim.animations[0];
      });
      const animMove = new FBXLoader();
      animMove.load('/Anim_Walking.fbx', (anim) => {
        self.animations["walking"] = anim.animations[0];
      });
      const animBackwards = new FBXLoader();
      animBackwards.load('/Anim_Walking_Backwards.fbx', (anim) => {
        self.animations["backward"] = anim.animations[0];
      });
      const animLeft = new FBXLoader();
      animLeft.load('/Anim_HipHopDance.fbx', (anim) => {
        self.animations["dance"] = anim.animations[0];
      });
      const animRight = new FBXLoader();
      animRight.load('/Anim_Walking_Right.fbx', (anim) => {
        this.animations["right"] = anim.animations[0];
      });
    });

    const loader = new GLTFLoader();
    this.dungeonScene = undefined;
    this.spaceScene = undefined;

    loader.load('/space_scene_lobby.glb', (gltf) => {

      this.spaceScene = gltf.scene;
      getRenderService().scene.add(gltf.scene);
      getRenderService().listener.setMasterVolume(0);

      gltf.scene.traverse(child => {
        if (child.type == "PointLight") {
          child.intensity /= 400;
        }
        if (child.name === "Chest_Open") {
          child.visible = false;
        }
        if (child.name === "Scroll_Chest") {
          child.visible = false;
        }
        if (child.isMesh) {
          if (child.material) {
            child.material.roughness = 1;
            child.material.metalness = 0;
          }
        }

      });


      fetch("lobby_replay.txt")
        .then(response => response.text())
        .then(data => {
          self.playback = data.split(/\r?\n|\r|\n/g);;
          self.startTime = 26000;
          self.lastTick = (new Date()).getTime();
          self.playbackTime = self.startTime;
          self.playbackIndex = 0;
          window.setInterval(self.animationPlayback, 10);
        });

      getRenderService().animate();

      getRenderService().triggerCallback("sceneupdate", getRenderService().scene);
      let loginTargetPos = new THREE.Vector3(0, 0, 0);
      /*getRenderService().addCallback("scenetick", (timediff, rs) => {
        var camPos = rs.camera.position.clone();       // Holds current camera position
        const numberOfPlayers = Object.values(this.state.users).filter(u => !u._data.offline).length;
        loginTargetPos.x = rs.camera.position.x;
        loginTargetPos.y = rs.camera.position.y;
        loginTargetPos.z = rs.camera.position.z;


        // Interpolate camPos toward targetPos
        rs.camera.position.lerp(loginTargetPos, 3 * timediff);
      });*/

    });

  }

  animationPlayback() {
    let rerun = true;
    this.playbackTime += (new Date()).getTime() - this.lastTick;
    this.lastTick = (new Date()).getTime();
    while (rerun) {
      let curMsg = JSON.parse(this.playback[this.playbackIndex]);
      if (curMsg.t < this.playbackTime) {
        this.playbackIndex++;
      } else {
        rerun = false;
      }
      if (this.playbackIndex >= this.playback.length - 3) {
        this.playbackIndex = 0;
        this.playbackTime = this.startTime;
      }
      Crowded._parseMessage(JSON.parse(curMsg.m));
    }
  }

  loadOutfit(outfit, index) {
    const loader = new THREE.TextureLoader();
    const self = this;
    // load a resource
    loader.load(
      // resource URL
      outfit,

      // onLoad callback
      function (texture) {
        // in this example we create the material when the texture is loaded
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.NearestFilter;
        const material = new THREE.MeshPhongMaterial({
          map: texture,
          color: 0x999999,
          shininess: 0
        });
        self.outfits[index] = material;
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function (err) {
        console.error('An error happened.');
      }
    );
  }

  render() {
    const self = this;

    getRenderService().onWindowResize();

    return [<PlayerRepresentation drawMe={1} />,
      ,
    <section class="hero is-info is-medium is-bold" id="home">
      <div class="hero-head">
        <nav class="navbar">
          <div class="container">
            <div class="navbar-brand">
              <a class="navbar-item" href="../">

                <img className="image" src="/images/escape_party.svg" alt="" style={{ maxHeight: "unset" }} width="120px" />
              </a>
              <span  className={"navbar-burger burger "+(this.state.burger?"is-active":"")} data-target="navbarMenu" onClick={()=>{self.setState({burger: !this.state.burger})}}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div id="navbarMenu" className={"navbar-menu "+(this.state.burger?"is-active":"")} style={{"background":"transparent"}}>
              <div class="navbar-end">
                <div class="tabs is-right">
                  <ul>
                    <li class="is-active"><a href="#home">Home</a></li>
                    <li><a href="#features">Features</a></li>
                    <li><a href="#rooms">{getTranslationService().translate("landing_nav_rooms")}</a></li>
                    <li><a href="#testimonials">Testimonials</a></li>
                  </ul>
                  <span class="navbar-item">
                    <LanguageSelector inline={true}></LanguageSelector>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">
          {getTranslationService().translate("landing_hero")}
          </h1>
          <h2 class="subtitle">
          {getTranslationService().translate("landing_hero_desc")}
          </h2>
          <a href='/login'><button className='button is-primary is-outlined is-medium is-focused'>{getTranslationService().translate("landing_cta")}</button></a>
        </div>
      </div>
    </section>,

    <section class="section" style={{ minHeight: "max(50vh,300px)", filter: "brightness(1.5)", position: "relative" }} id="three-container-hero"></section>,

    <div class="box cta mb-0">
      <p class="has-text-centered">
        <span class="tag is-primary">Limited Offer</span> Use code <code>EARLY_ADOPTER</code> to get 50% off your purchase.
      </p>
    </div>,
    <section class="container section" id="features">
      <div class="intro column is-12 has-text-centered">
        <h2 class="title"> {getTranslationService().translate("landing_features")}</h2><br />
        <p class="subtitle" dangerouslySetInnerHTML={{__html: getTranslationService().translate("landing_features_desc")}}>
        </p>
      </div>
      <div class="sandbox mt-4  has-text-centered">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div className='card-icon'>
                <FontAwesomeIcon icon={faCube} size="lg" />
              </div>
              <div className='card-content'>
                <h4 class="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_3d_title")}</h4>
                <p class="subtitle has-text-grey">{getTranslationService().translate("landing_3d_desc")}</p>
              </div>
            </article>
          </div>
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div className='card-icon'>
                <FontAwesomeIcon icon={faChrome} size="lg" />
              </div>
              <div className='card-content'>
                <h4 className="is-size-4 has-text-weight-bold mb-2">
                  {getTranslationService().translate("landing_no_installation_title")}</h4>
                <p className="subtitle has-text-grey">
                  {getTranslationService().translate("landing_no_installation_desc")}
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div className='card-icon'>
                <FontAwesomeIcon icon={faLanguage} size="lg" />
              </div>
              <div className='card-content'>
                <h4 className="is-size-4 has-text-weight-bold mb-2">
                  {getTranslationService().translate("landing_multi_language")}</h4>
                <p className="subtitle has-text-grey">
                  {getTranslationService().translate("landing_multi_language_desc")}
                </p>
              </div>
            </article>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div className='card-icon'>
                <FontAwesomeIcon icon={faEarthAmericas} size="lg" />
              </div>
              <div className='card-content'>
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_location_independent_title")}</h4>
                <p className="subtitle has-text-grey">
                  {getTranslationService().translate("landing_location_independent_desc")}
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div className='card-icon'>
                <FontAwesomeIcon icon={faClock} size="lg" />
              </div>
              <div className='card-content'>
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_any_time_title")}</h4>
                <p className="subtitle has-text-grey">{getTranslationService().translate("landing_any_time_desc")}</p>
              </div>
            </article>
          </div>
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div className='card-icon'>
                <FontAwesomeIcon icon={faPeopleGroup} size="lg" />
              </div>
              <div className='card-content'>
                <h4 className="is-size-4 has-text-weight-bold mb-2">{getTranslationService().translate("landing_any_grou_size_title")}</h4>
                <p className="subtitle has-text-grey">{getTranslationService().translate("landing_any_grou_size_desc")}</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>,
      ,
    <section class="container section" id="rooms">
      <div class="intro column is-12 has-text-centered">
        <h2 class="title">{getTranslationService().translate("landing_our_rooms")}</h2><br />
        <p class="subtitle">
        {getTranslationService().translate("landing_our_rooms_desc")}
        </p>
      </div>
      <div class="sandbox mt-4">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img src="/images/rooms/AztecTemplePeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{getTranslationService().translate("lobby_room3")}</p>
                    <p class="subtitle is-6">{getTranslationService().translate("lobby_room3_desc")}</p>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <a href='/login' class="card-footer-item">{getTranslationService().translate("landing_cta")}</a>
              </footer>
            </article>
          </div>
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img src="/images/rooms/DungeonPeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{getTranslationService().translate("lobby_room1")}</p>
                    <p class="subtitle is-6">{getTranslationService().translate("lobby_room1_desc")}</p>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <a href='/login' class="card-footer-item">{getTranslationService().translate("landing_cta")}</a>
              </footer>
            </article>
          </div>
          <div class="tile is-parent is-shady">
            <article class="tile is-child card is-white">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img src="/images/rooms/SpaceshipPeople.jpg" style={{ "objectFit": "cover" }} alt="Placeholder image" />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{getTranslationService().translate("lobby_room2")}</p>
                    <p class="subtitle is-6">{getTranslationService().translate("lobby_room2_desc")}</p>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <a href='/login' class="card-footer-item">{getTranslationService().translate("landing_cta")}</a>
              </footer>
            </article>
          </div>
        </div>
      </div>
    </section>,
    <section className='section' id="testimonials">
      <h1 class="title has-text-centered mb-6">
          {getTranslationService().translate("landing_testimonials")}
      </h1>
      <div class="columns features">
        <div class="column is-4">
          <div class="box has-background-white has-text-grey-dark">
            <p>
              <span class="font-bold has-text-primary is-size-5">
                “
              </span>
              Interesting concept and I really liked that it was browser based (no additional install needed).<br />
              Runs great - no stuttering or anything.
              <span class="font-bold has-text-primary is-size-5">
                ”
              </span>
            </p>
            <div class="is-flex is-align-items-center is-justify-content-start mt-4">
              <a href="#" class="image is-48x48">
                <div class="is-rounded" style={{ backgroundColor: "lightgray", height: "100%", width: "100%", borderRadius: "100%" }} ></div>
              </a>
              <div class="is-flex is-flex-direction-column ml-2 is-align-content-space-between">
                <span class="font-semibold has-text-weight-bold">
                  Anna Mager
                </span>
                <span class="is-size-7 is-flex is-align-items-center">
                  Teamlead
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="box has-background-white has-text-grey-dark">
            <p>
              <span class="font-bold has-text-primary is-size-5">
                “
              </span>
              The easiest team building event I ever organized.<br />
              Starting and joining the room was as easy as clicking a link.
              It was great fun for us and our international colleagues.<br />
              Although it was tough we got through it as a team.
              Thanks for the fun event.
              <span class="font-bold has-text-primary is-size-5">
                ”
              </span>
            </p>
            <div class="is-flex is-align-items-center is-justify-content-start mt-4">
              <a href="#" class="image is-48x48">
                <div class="is-rounded" style={{ backgroundColor: "lightgray", height: "100%", width: "100%", borderRadius: "100%" }} ></div>
              </a>
              <div class="is-flex is-flex-direction-column ml-2 is-align-content-space-between">
                <span class="font-semibold has-text-weight-bold">
                  Tom Caboni
                </span>
                <span class="is-size-7 is-flex is-align-items-center">
                  International Teamlead
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="box has-background-white has-text-grey-dark">
            <p>
              <span class="font-bold has-text-primary is-size-5">
                “
              </span>
              I played other online escape rooms.
              These are on a totally different level.
              It's way more immersive in 3D out of an ego perspective.
              In other online escape rooms we only stared at a static image.
              <span class="font-bold has-text-primary is-size-5">
                ”
              </span>
            </p>
            <div class="is-flex is-align-items-center is-justify-content-start mt-4">
              <a href="#" class="image is-48x48">
                <div class="is-rounded" style={{ backgroundColor: "lightgray", height: "100%", width: "100%", borderRadius: "100%" }} ></div>
              </a>
              <div class="is-flex is-flex-direction-column ml-2 is-align-content-space-between">
                <span class="font-semibold has-text-weight-bold">
                  Marce Benx
                </span>
                <span class="is-size-7 is-flex is-align-items-center">
                  Happy Customer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
      ,
    <section className='mt-4'>
      <div class="hero-body bottom-cta has-text-white ">
        <div class="container has-text-centered">
          <h1 class="title">
            {getTranslationService().translate("landing_hero")}
          </h1>
          <h2 class="subtitle">
            {getTranslationService().translate("landing_hero_desc")}
          </h2>
          <a href='/login'><button className='button is-primary is-outlined is-medium is-focused'>{getTranslationService().translate("landing_cta")}</button></a>
        </div>
      </div>
    </section>
      ,
    <footer class="footer">
      <div class="container">
        <div class="columns">
          <div class="column is-3 is-offset-2">
            <a className="is-inline-block" href="https://uluni.de">
              <img className="image" src="/images/uluni.svg" alt="" width="96px" /></a>

          </div>
          <div class="column is-3">
            <h2><strong>Nils Wenzler Informatik</strong></h2>
            <ul>
              <li><a href="/impressum.html">Impressum</a></li>
            </ul>
          </div>
          <div class="column is-4">
          </div>
        </div>

      </div>
      <script src="../js/bulma.js"></script>
    </footer>

    ];
  }

}


export default LandingPage2;
